import {ENV} from "../utils/constants";

export type SolutionKey = "web-intelligence" | "web-forensics" | "web-trust" | "web-sign";

export interface Solution {
    basePrice: number;
    modality?: "year" | "month";
    subtext?: string;
    link?: string;
    customCTAKey?: string;
    customCTA?: string;
}

export type SolutionDef = {
    [k in SolutionKey]?: Solution;
};

export const SOLUTIONS: SolutionDef = {
    "web-intelligence": {
        basePrice: 500,
        // modality: "month",
        customCTAKey: "web-intelligence.customButtonLabel",
        customCTA: "web-intelligence.customCTA",
    },
    "web-forensics": {
        basePrice: 75,
        link: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webforensics.kopjra.com`,
    },
    "web-trust": {
        basePrice: 50,
        modality: "month",
        link: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com`,
    },
    "web-sign": {
        basePrice: 15,
        modality: "month",
        link: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.websign.kopjra.com`,
    },
};
