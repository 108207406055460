import {connect} from "react-redux";
import {DispatchProps, GodMode, InnerProps, StateProps} from "../components/GodMode";
import {RootState, ThunkDispatch} from "../types";
import {doDownloadCustomerCSV, doGetConsumptionStats} from "../actions/thunks/customer";

function mapStateToProps(rs: RootState): StateProps {
    return {
        consumptionStats: rs.customer.consumptionStats,
        consumptionStatsNext: rs.customer.consumptionStatsNext,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onDownloadCSV: () => dispatch(doDownloadCustomerCSV()),
        onGetConsumptionStats: (query) => dispatch(doGetConsumptionStats(query)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(GodMode);