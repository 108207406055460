import React, {useEffect, useState} from "react";
import {
    alertBox,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KContainer,
    KForm,
    KInput,
    KOption,
    KSelect,
    KSELECT_BACKCOLOR,
    KSELECT_SIZE,
    KSpace,
    KSpinner,
    KSpinnerSize
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {Col, Row} from "react-bootstrap";
import {PackagePrice, PRICE_TYPE, Product} from "../types/products";
import {getTranslationElement} from "../utils/commons";
import {CreateSubscribeUserOutput, CreateSubscribeUserParams} from "../types/customer";

export interface StateProps {
    products?: Product[];
}

export interface DispatchProps {
    onGetProducts: () => void;
    onCreateSubscribeUser: (params: CreateSubscribeUserParams) => Promise<CreateSubscribeUserOutput>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Admin: React.FC<Props> = ( {onCreateSubscribeUser, products, onGetProducts}: Props) => {
    const [submitting, setSubmitting] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        onGetProducts();
    }, [onGetProducts]);

    let wfPrices: KOption<string | string[]>[] = [];
    if (products && products.length) {
        for (const product of products) {
            if (product.solution === "web-forensics") {
                wfPrices = (product.prices as PackagePrice[]).map((p) => ({
                    value: p.id,
                    label: getTranslationElement(`${product.id}.${PRICE_TYPE.package}.item`, `${product.id}.${PRICE_TYPE.package}.${p.description}.${p.duration}`, {
                        count: I18n.l(p.to),
                        price: "/",
                        duration: I18n.t(`generic.duration.${p.duration}` as any),
                        dangerousHTML: true,
                    }, p.linked),
                }));
            }
        }
    }

    return (
        <KContainer>
            <Row>
                <Col lg={6} md={10} sm={12}>
                    <KCard header={<Translate value={"admin.cardTitle"}/>} >
                        <h6><Translate value={"admin.description"}/></h6>
                        <KSpace/>
                        <KForm key={key} onSubmit={async (values) => {
                            console.log(values);

                            const params: CreateSubscribeUserParams = {
                                productId: "web-forensics",
                                email: `${values.email}`,
                                priceIds: `${values.price}`.split(","),
                                firstName: `${values.firstName}`,
                                lastName: `${values.lastName}`,
                            }
                            let errorMessage: string = "";
                            let result: CreateSubscribeUserOutput | undefined;
                            try {
                                result = await onCreateSubscribeUser(params);
                            } catch (error) {
                                errorMessage = (error as Error).message;
                            }

                            let message: string;
                            if (result) {
                                if (result.createdNewUser) {
                                    message = I18n.t("admin.created");
                                } else {
                                    message = I18n.t("admin.updated");
                                }
                                setKey(key + 1);
                            } else {
                                message = I18n.t("admin.error", {errorMessage});
                            }

                            await alertBox({
                                message,
                                size: "lg",
                                func: () => {
                                   // setPriceId(undefined as any);
                                }
                            });
                        }} onSubmitting={setSubmitting}>
                            <Row>
                                <Col>
                                    <KInput id={"firstName"} label={<Translate value={"admin.firstName"}/>} required={true}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <KInput id={"lastName"} label={<Translate value={"admin.lastName"}/>} required={true}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <KInput id={"email"} label={<Translate value={"admin.email"}/>} type={"email"} required={true}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <KSelect<string | string[]> clearable={true} id={"price"} options={wfPrices} label={<Translate value={"admin.package"}/>} required={true} backColor={KSELECT_BACKCOLOR.gray} size={KSELECT_SIZE.lg}/>
                                </Col>
                            </Row>
                            <KSpace/>
                            <Row>
                                <Col>
                                    {!submitting ?
                                        <KButton text={<Translate value={"admin.addUpdate"}/>} type={KBUTTON_TYPE.submit} variant={KBUTTON_VARIANT.primary} fill={true}/> :
                                        <KSpinner size={KSpinnerSize.lg}/>
                                    }
                                </Col>
                            </Row>
                        </KForm>
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    );
}
