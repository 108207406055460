import {connect} from "react-redux";
import {Dispatch} from "redux";
import {DispatchProps, EmailVerified, InnerProps, StateProps} from "../components/EmailVerified";
import {RootAction} from "../actions";
import {RootState} from "../types";
import {navigate, Routes} from "../utils/router";

function mapStateToProps(): StateProps {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch<RootAction>): DispatchProps {
    return {
        onGoToHome: () => navigate(Routes.HOME, {replace: true}),
        onGoToPayment: () => navigate(Routes.PAYMENT, {replace: true}),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(EmailVerified);
