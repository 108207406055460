import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";

export interface StateProps {
}

export interface DispatchProps {
    onGoToPayment: () => void;
    onGoToHome: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const EmailVerified: React.FC<Props> = ({onGoToHome, onGoToPayment}) => {
    const {user} = useAuth0();
    useEffect(() => {
        if (user && user.email_verified) {
            onGoToPayment();
        } else {
            onGoToHome();
        }
    })
    return null;
}