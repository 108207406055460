import {createReducer} from "typesafe-actions";
import update, {Spec} from "immutability-helper";
import {CartType, ProductState} from "../types/products";
import {
    addSubscriptionError,
    addToCart,
    cartError,
    emptyCart,
    gotProducts,
    initSubscriptionErrors,
    removeFromCart,
    resetOldCart,
    selectSolution,
    setCartLoading,
    setExclusivePrices,
    setOldCart,
} from "../actions/products";
import {getFromLocalStorage, setToLocalStorage} from "../utils/commons";
import {CART_KEY} from "../utils/constants";
import {v4} from "uuid";
import {gotPromotionCode} from "../actions/customer";
import Stripe from "stripe";

interface SavedCart {
    cart: CartType;
    uuid: string;
    promotion?: Stripe.PromotionCode;
}

const initialState: ProductState = {
    cart: getFromLocalStorage<SavedCart>(CART_KEY)?.cart || {},
    cartUUID: getFromLocalStorage<SavedCart>(CART_KEY)?.uuid || undefined,
    promotion: getFromLocalStorage<SavedCart>(CART_KEY)?.promotion,
    cartLoading: false,
    cartError: false,
};

export const productsReducer = createReducer(initialState)
    .handleAction(gotProducts, (state, action) => update(state, {
        products: {$set: action.payload.products},
        taxRates: {$set: action.payload.taxRate},
    }))
    .handleAction(selectSolution, (state, action) => update(state, {
        selectedSolution: {$set: action.payload},
    }))
    .handleAction(addToCart, (state, action) => {
        const $spec: Spec<ProductState> = {
            cart: {$set: {...state.cart, ...action.payload}},
            promotion: {$set: undefined},
        };
        if (!state.cart.length) {
            $spec.cartUUID = {$set: v4()};
        }
        const newState = update(state, $spec);
        setToLocalStorage<SavedCart>(CART_KEY, {cart: newState.cart, uuid: newState.cartUUID || ""});
        return newState;
    })
    .handleAction(gotPromotionCode, (state, action) => {
        const newState = update(state, {
            promotion: {$set: action.payload},
        });
        setToLocalStorage<SavedCart>(CART_KEY, {cart: newState.cart, uuid: newState.cartUUID || "", promotion: newState.promotion});
        return newState;
    })
    .handleAction(emptyCart, (state) => {
        const newState = update(state, {
            cart: {$set: {}},
            cartUUID: {$set: v4()},
            promotion: {$set: undefined},
        });
        setToLocalStorage<SavedCart>(CART_KEY, {cart: newState.cart, uuid: newState.cartUUID || ""});
        return newState;
    })
    .handleAction(removeFromCart, (state, action) => {
        const ids = Array.isArray(action.payload) ? action.payload : [action.payload];
        const $spec: Spec<ProductState> = {
            cart: {$unset: ids},
            promotion: {$set: undefined},
        };
        if (Object.keys(state.cart).length === ids.length) {
            $spec.cartUUID = {$set: undefined};
        }
        const newState = update(state, $spec);
        setToLocalStorage<SavedCart>(CART_KEY, {cart: newState.cart, uuid: newState.cartUUID || ""});
        return newState;
    })
    .handleAction(setOldCart, (state, action) => update(state, {
        oldCart: {$set: action.payload},
    }))
    .handleAction(initSubscriptionErrors, (state) => update(state, {
        subscriptionErrors: {$set: new Map()},
    }))
    .handleAction(addSubscriptionError, (state, action) => update(state, {
        subscriptionErrors: {$add: [[action.payload.pid, action.payload.error]]}
    }))
    .handleAction(resetOldCart, (state) => update(state, {
        subscriptionErrors: {$set: undefined},
        oldCart: {$set: undefined},
        cartUUID: {$set: v4()},
        promotion: {$set: undefined},
    }))
    .handleAction(setCartLoading, (state, action) => update(state, {
        cartLoading: {$set: action.payload}
    }))
    .handleAction(cartError, (state) => update(state, {
        cartError: {$set: true},
    }))
    .handleAction(setExclusivePrices, (state, action) => update(state, {
        exclusives: {$set: action.payload}
    }))
;