import {combineReducers, Reducer} from "redux";
import {kAlertReducer, kpCursedTableReducer, kptableReducer} from "@kopjra/uikit";
import {i18nReducer, I18nState} from "react-redux-i18n";
import {productsReducer} from "./products";
import {customerReducer} from "./customer";

export const rootReducer = combineReducers({
    i18n: i18nReducer as unknown as Reducer<I18nState>,
    alert: kAlertReducer,
    kptable: kptableReducer,
    kpcursedtable: kpCursedTableReducer,
    product: productsReducer,
    customer: customerReducer,
});