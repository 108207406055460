import {connect} from "react-redux";
import {Cart, DispatchProps, InnerProps, StateProps} from "../components/Cart";
import {RootState, ThunkDispatch} from "../types";
import {navigate, Routes} from "../utils/router";
import {removeFromCart} from "../actions/products";
import {confirmBox} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";
import {gotPromotionCode} from "../actions/customer";
import {IVA} from "../utils/constants";
import {doGetPromotionCode, doInitCartFromQuery} from "../actions/thunks/products";
import {doGetProductExclusive} from "../actions/thunks/customer";

function mapStateToProps({product}: RootState): StateProps {
    return {
        cart: product.cart,
        promotion: product.promotion,
        tax: (product.taxRates?.percentage || IVA * 100) / 100,
        loading: product.cartLoading,
        cartError: product.cartError,
        exclusives: product.exclusives,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGoHome: () => {navigate(Routes.HOME); },
        onRemoveFromCart: async (id) => { await confirmBox({
            message: I18n.t("cart.deleteConfirm"),
            yesText: I18n.t("generic.yes"),
            noText:  I18n.t("generic.no"),
            yesFunc: () => { dispatch(removeFromCart(id)); },
        })},
        onEditCartElement: (solution, id) => {navigate(Routes.SOLUTION_DETAIL(solution, id));},
        onProceed: () => {navigate(Routes.PAYMENT)},
        onGetPromotion: (code) => dispatch(doGetPromotionCode(code)),
        onRemovePromotion: () => dispatch(gotPromotionCode(undefined)),
        onCreateCartFromQuery: (priceId, code) => dispatch(doInitCartFromQuery(priceId, code)),
        onGetProductExclusive: () => dispatch(doGetProductExclusive()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Cart);
