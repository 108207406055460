import {
    BillingInfo,
    CreateSubscribeUserOutput,
    CreateSubscribeUserParams,
    Customer,
    CustomerConsumptionStats,
    GetConsumptionStatsParams,
    GetInvoicesParams,
    Invoice,
    InvoiceWithPDF,
    PaymentInfo
} from "../types/customer";
import {toBillingInfo, toCustomer, toCustomerConsumptionStats, toUserBillingInfo} from "./dto/customer";
import {apiCall, FetchError, HttpMethod} from "./index";
import {UserProfileData} from "../types";
import {SubscriptionError} from "../types/products";
import {Paginated} from "../utils/commons";
import Stripe from "stripe";

export async function getCustomer(): Promise<Customer> {
    const res = (await apiCall({
        urlComponent: `users/current`,
    }));
    return toCustomer(await res.json());
    // return toCustomer(await getPICustomerMock());
}

export async function getBillingInfo(): Promise<BillingInfo> {
    const res = (await apiCall({
        urlComponent: `users/current/billingInfo`,
    }));
    return toBillingInfo(await res.json());
}

export async function saveBillingInfo(id: number, bi: BillingInfo): Promise<BillingInfo> {
    const res = (await apiCall({
        urlComponent: `users/current/billingInfo`,
        body: toUserBillingInfo(bi),
        method: HttpMethod.PUT,
    }));
    return toBillingInfo(await res.json());
    // return toCustomer(await getCustomerMock());
}

export async function getSubscription(id: string): Promise<Stripe.Subscription> {
    const res = await apiCall({
        urlComponent: `users/current/subscriptions/${id}`,
        method: HttpMethod.GET,
    });
    return await res.json();
}

export async function getSubscriptions(): Promise<(Stripe.Subscription & {invoices: Stripe.Invoice[]})[]> {
    const res = await apiCall({
        urlComponent: `users/current/subscriptions`,
        method: HttpMethod.GET,
    });
    return await res.json();
}

export async function cancelSubscription(subId: string): Promise<Stripe.Subscription> {
    const res = await apiCall({
        urlComponent: `users/current/subscriptions/${subId}`,
        method: HttpMethod.DELETE,
        contentType: "none",
    });
    return await res.json();
}

export async function updateSubscription(subscriptionId: string, newPriceIds: string[], newFixedPriceIds: string[], promoCode?: string): Promise<Stripe.Subscription | SubscriptionError> {
    try {
        const res = await apiCall({
            urlComponent: `/users/current/subscriptions/${subscriptionId}`,
            method: HttpMethod.PUT,
            body: {
                newPriceIds,
                newFixedPriceIds,
                promoCode,
            }
        });
        return await res.json();
    } catch (err) {
        if (err instanceof FetchError) {
            switch (err.response.status) {
                case 402:
                    return SubscriptionError.PAYMENT_ERROR;
            }
            return SubscriptionError.RESPONSE_ERROR;
        } else {
            return SubscriptionError.RESPONSE_ERROR;
        }
    }
}

export async function createSubscribeUser(params: CreateSubscribeUserParams): Promise<CreateSubscribeUserOutput> {
    const res = await apiCall({
        urlComponent: `/users/current/manualSubscriptions`,
        method: HttpMethod.POST,
        body: params,
    });
    return await res.json();
}


export async function subscribeToPlan(isOneTime: boolean, priceIds: string[], fixedPriceIds: string[], promoCode?: string): Promise<Stripe.Subscription | SubscriptionError> {
    try {
        const res = await apiCall({
            urlComponent: `/users/current/subscriptions`,
            method: HttpMethod.POST,
            body: {
                isOneTime,
                priceIds,
                fixedPriceIds,
                promoCode
            }
        });
        return await res.json();
    } catch (err) {
        if (err instanceof FetchError) {
            switch (err.response.status) {
                case 402:
                    return SubscriptionError.PAYMENT_ERROR;
            }
            return SubscriptionError.RESPONSE_ERROR;
        } else {
            return SubscriptionError.RESPONSE_ERROR;
        }
    }
}

export async function getPaymentInfo(): Promise<PaymentInfo> {
    const res = (await apiCall({
        urlComponent: `/users/current/paymentMethods`,
        method: HttpMethod.GET
    }));
    return await res.json();
}

export async function addPaymentMethod(pi_id: string): Promise<PaymentInfo> {
    const res = (await apiCall({
        urlComponent: `/users/current/paymentMethods`,
        method: HttpMethod.POST,
        body: {
            paymentMethodId: pi_id,
        },
    }));
    return await res.json();
}

export async function deletePaymentMethod(pi_id: string): Promise<PaymentInfo> {
    const res = await apiCall({
        urlComponent: `/users/current/paymentMethods/${pi_id}`,
        method: HttpMethod.DELETE,
        contentType: "none",
    });
    return await res.json();
}

export async function setDefaultPaymentMethod(pi_id: string): Promise<PaymentInfo> {
    const res = await apiCall({
        urlComponent: `/users/current/paymentMethods/default`,
        method: HttpMethod.POST,
        body: {
            paymentMethodId: pi_id,
        },
    });
    return await res.json();
    // return await setDefaultPaymentMethodMock();
}

export async function resendEmail() {
    await apiCall({
        urlComponent: `/users/current/sendVerificationEmail`,
        method: HttpMethod.POST,
        contentType: "none",
    });
}

export async function resetPassword() {
    await apiCall({
        urlComponent: `/users/current/resetPassword`,
        method: HttpMethod.POST,
        contentType: "none",
    });
}

export async function updateUserProfile(up: UserProfileData) {
    await apiCall({
        urlComponent: `/users/current`,
        method: HttpMethod.PUT,
        body: up,
    });
}

export async function getInvoices(query: GetInvoicesParams): Promise<Paginated<Invoice>> {
    const res = await apiCall({
        urlComponent: `/users/current/fiscaldocs`,
        query,
    });
    const json = await res.json();
    return {total: json.total, items: json.items.map((i: Invoice) => ({...i, timestamp: new Date(+i.timestamp * 1000)}))};
}

export async function getInvoice(id: number): Promise<InvoiceWithPDF> {
    const res = await apiCall({
        urlComponent: `/users/current/fiscaldocs/${id}`,
    });
    return await res.json();
}

export async function downloadCustomerCSV(): Promise<string> {
    const res = await apiCall({
        urlComponent: `/users/current/exportCustomers`,
    });
    return await res.text();
}

export async function getConsumptionStats(query: GetConsumptionStatsParams): Promise<CustomerConsumptionStats[]> {
    const res = await apiCall({
        urlComponent: `/users/current/customerConsumptionStats`,
        query
    });
    return (await res.json()).map(toCustomerConsumptionStats);
}
