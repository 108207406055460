import {connect} from "react-redux";
import {BillingInfoForm, DispatchProps, InnerProps, StateProps} from "../components/BillingInfoForm";
import {RootState, ThunkDispatch} from "../types";
import {doGetBillingInfo, doGetCustomer, doSaveBillingInfo} from "../actions/thunks/customer";

function mapStateToProps({i18n, customer}: RootState): StateProps {
    return {
        locale: i18n.locale,
        customer: customer.customer,
        billingInfo: customer.billingInfo,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCustomer: () => dispatch(doGetCustomer()),
        onSaveBillingInfo: (id, bi) => dispatch(doSaveBillingInfo(id, bi)),
        onGetBillingInfo: () => dispatch(doGetBillingInfo()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(BillingInfoForm);