import React, {useEffect} from "react";
import {BillingInfo, Customer} from "../types/customer";
import {KCard, KContainer, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import PaymentMethods from "../containers/PaymentMethods";

export interface StateProps {
    customer?: Customer;
    billingInfo?: BillingInfo;
}

export interface DispatchProps {
    onGetCustomer: () => Promise<void>;
    onGetBillingInfo: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const ManagePaymentMethods: React.FC<Props> = ({billingInfo, onGetBillingInfo, customer, onGetCustomer}) => {
    useEffect(() => {
        if (!customer) {
            onGetCustomer().catch(console.log);
        } else if (!billingInfo) {
            onGetBillingInfo().catch(console.log);
        }
    }, [billingInfo, customer, onGetBillingInfo, onGetCustomer]);

    return (
        <KContainer>
            <Row>
                <Col lg={6} md={10} sm={12}>
                    {(!customer || !billingInfo) ? <h1><KSpinner size={KSpinnerSize.nm}/></h1> : (
                        <KCard header={<Translate value={`payment.paymentMethod`}/>}>
                            <PaymentMethods
                                buttonLabel={<Translate value="payment.setDefault"/>}
                                onHandler={() => {}}
                            />
                        </KCard>
                    )}
                </Col>
            </Row>
        </KContainer>

    );
}