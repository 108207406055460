import {connect} from "react-redux";
import {Dispatch} from "redux";
import {UserBillingInfo, DispatchProps, StateProps, InnerProps} from "../components/UserBillingInfo";
import {RootState} from "../types";
import {RootAction} from "../actions";

function mapStateToProps(): StateProps {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch<RootAction>): DispatchProps {
    return {};
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(UserBillingInfo);