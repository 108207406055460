import {connect} from "react-redux";
import {DispatchProps, InnerProps, ManagePaymentMethods, StateProps} from "../components/ManagePaymentMethods";
import {RootState, ThunkDispatch} from "../types";
import {doGetBillingInfo, doGetCustomer} from "../actions/thunks/customer";

function mapStateToProps({customer}: RootState): StateProps {
    return {
        customer: customer.customer,
        billingInfo: customer.billingInfo,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetCustomer: () => dispatch(doGetCustomer()),
        onGetBillingInfo: () => dispatch(doGetBillingInfo()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(ManagePaymentMethods);