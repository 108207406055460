import {AnyPrice, ProductIdType} from "./products";
import Stripe from "stripe";
import BEStripe from "stripe";
import {getProvincesFromCountry} from "../utils/provinces";
import {SolutionKey} from "./solutions";

export interface CustomerAddress {
    street?: string;
    postalCode?: string;
    city?: string;
    province?: string;
    countryISOCodeAlpha2?: string;
}

export enum PLAN_SUBSCRIPTION_STATUS {
    "ACTIVE" = "active", // subscription period started
    "PAST_DUE" = "past_due", // subscription period started, all invoices paid
    "UNPAID" = "unpaid", // subscription period started, some payment error
    "CANCELED" = "canceled", // subscription period ended
    "INCOMPLETE" = "incomplete", // subscription period ended, all invoices paid
    "INCOMPLETE_EXPIRED" = "incomplete_expired", // subscription period ended, some payment error
    "TRIALING" = "trialing", // subscription period ended, some payment error
}

export enum INVOICE_STATUS {
    "NOT_PAID" = "NOT_PAID", // no tranche has been paid
    "FULLY_PAID" = "FULLY_PAID", // all tranches paid
    "FAILED_PAYMENTS" = "FAILED_PAYMENTS", // at least one tranche payment failed
}

export interface BillingInfo {
    ragioneSociale?: string;
    address: CustomerAddress;
    email?: string;
    pIVA?: string;
    codiceFiscale?: string;
    invoiceEmail?: string;
}

export function hasBillingInfo(c?: BillingInfo): boolean {
    return !!(c?.ragioneSociale && c.email && (c.pIVA || c.codiceFiscale)
        && c.address?.city && c.address.countryISOCodeAlpha2
        && c.address.postalCode && c.address.province
        && Object.keys(getProvincesFromCountry(c.address.countryISOCodeAlpha2)).indexOf(c.address.province || "") !== -1
        && c.address.street
    );
}

export interface Customer {
    id: number;
    name: string;
    email: string;
    stripeId: string;
}

export interface PlanSubscriptionWithTotal {
    id: string;
    productNames: string[];
    solutionKeys: string[];
    prices: AnyPrice[];
    total: number;
    cancelAt?: Date;
    canceledAt?: Date;
    created: Date;
    status: Stripe.Subscription.Status;
    startDate?: Date;
    customer: Customer;
    discount?: Stripe.Discount;
    tax: number;
    cancelAtPeriodEnd: boolean;
    nextRenew?: Date;
    oneTime: boolean;
}

export interface PaymentInfo {
    defaultPaymentMethodId?: string;
    paymentMethods: Stripe.PaymentMethod[];
}

export interface Invoice {
    id: number;
    type: string;
    sdiProviderType: string;
    sdiProviderId: string;
    sdiId: string;
    sdiAnno: number;
    stripeInvoiceId: string;
    timestamp: Date;
    netTotal: number;
    taxTotal: number;
    grandTotal: number;
    productNames: string[];
    solutionKeys: string[];
    invoice: BEStripe.Invoice | null;
}

export interface GetInvoicesParams {
    top?: number;
    skip?: number;
    sortBy?: string;
    type?: string;
}

export interface InvoiceWithPDF {
    pdfLink: string;
}

export interface GetConsumptionStatsParams {
    limit?: number;
    direction?: "prev" | "next";
    current?: string;
    customerEmail?: string;
    partner?: string;
}

export interface CustomerState {
    customer?: Customer;
    billingInfo?: BillingInfo;
    paymentInfo?: PaymentInfo;
    invoices?: Invoice[];
    totalInvoices: number;
    subscriptions?: PlanSubscriptionWithTotal[];
    totalSubscriptions: number;
    subscribing: boolean;
    consumptionStats?: CustomerConsumptionStats[];
    consumptionStatsNext?: string;
}

export interface CreateSubscribeUserParams {
    productId: ProductIdType;
    email: string;
    priceIds: string[];
    firstName: string;
    lastName: string;
}

export interface CreateSubscribeUserOutput {
    createdNewUser: boolean;
}

export interface CustomerConsumptionStats {
    customerEmail: string;
    customerId: string;
    customerBillingEmail: string;
    partner: string;
    availability: ProductAvailability[];
}

export interface ProductAvailability {
    productInternalId: ProductIdType;
    productId: string;
    solution: SolutionKey;
    activeSubs: SubscriptionExtract[];
    current: {
        purchased: number;
        consumed: number;
        trialing: boolean;
        trialingConsumed: number;
    },
    historical: {
        purchased: number;
        consumed: number;
        trialed: boolean;
        trialedConsumed: number;
    }
}

export interface SubscriptionExtract {
    id: string;
    cancel_at: Date | null;
    current_period_end: Date;
    current_period_start: Date;
}

