import {connect} from "react-redux";
import {DispatchProps, InnerProps, Invoices, StateProps} from "../components/Invoices";
import {RootState, ThunkDispatch} from "../types";
import {doDownloadInvoice, doGetInvoices} from "../actions/thunks/customer";

function mapStateToProps({customer}: RootState): StateProps {
    return {
        invoices: customer.invoices,
        total: customer.totalInvoices,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetInvoices: (query) => dispatch(doGetInvoices(query)),
        onDownloadInvoice: async (id) => dispatch(doDownloadInvoice(id)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Invoices);