import {
    BillingInfo,
    Customer,
    CustomerAddress,
    CustomerConsumptionStats,
    Invoice,
    PLAN_SUBSCRIPTION_STATUS,
    PlanSubscriptionWithTotal,
    ProductAvailability,
    SubscriptionExtract
} from "../../types/customer";
import Stripe from "stripe";
import BEStripe from "stripe";
import {AnyPrice, Price, Product, ProductIdType} from "../../types/products";
import {getProductById} from "../../utils/commons";
import {SolutionKey} from "../../types/solutions";
import {getAddons, getConsumptionPrices, getLevelPrices, getPackagePrices} from "./products";

export function toCustomerAddress(addr: Stripe.Address): CustomerAddress {
    return addr ? ({
        city: addr.city || undefined,
        countryISOCodeAlpha2: addr.country || undefined,
        postalCode: addr.postal_code || undefined,
        street: addr.line1 || undefined,
        province: addr.state || undefined,
    }) : {};
}

export function toStripeAddress(addr: CustomerAddress): Stripe.Address | undefined {
    return Object.keys(addr).length === 0 ? undefined : ({
        city: addr.city ||  null,
        line1: addr.street || null,
        postal_code: addr.postalCode || null,
        country: addr.countryISOCodeAlpha2 || null,
        state: addr.province || null,
        line2: null,
    });
}

export function toBillingInfo(obj: any): BillingInfo {
    return {
        address: toCustomerAddress(obj.address),
        codiceFiscale: obj.taxCode,
        email: obj.email,
        invoiceEmail: obj.invoiceEmail,
        pIVA: obj.vatNumber,
        ragioneSociale: obj.name,
    };
}

export function toCustomer(obj: any): Customer {
    return {
        id: obj.id,
        email: obj.email,
        name: obj.name,
        stripeId: obj.stripeCustomerId,
    };
}

export function toUserBillingInfo(bi: BillingInfo): any {
    return {
        email: bi.email,
        invoiceEmail: bi.invoiceEmail,
        name: bi.ragioneSociale,
        vatNumber: bi.pIVA,
        taxCode: bi.codiceFiscale,
        address: toStripeAddress(bi.address),
    };
}

export function getProductNamesFromPrices(rawPrices: BEStripe.Price[], products: Product[]) {
    return Array.from(
        rawPrices.reduce<Set<ProductIdType | "">>(
            (previousValue, currentValue) => {
                const productById = getProductById(currentValue?.product as string || "", products || []);
                previousValue.add(productById?.id || "");
                return previousValue;
            }, new Set()
        ),
    ).filter(value => !!value) as ProductIdType[];
}

export function getOneTimeFromPrices(rawPrices: BEStripe.Price[], products: Product[]) {
    return rawPrices.reduce<boolean>(
        (previousValue, currentValue) => {
            const productById = getProductById(currentValue?.product as string || "", products || []);
            const priceById: Price | undefined = productById?.prices.find((price) => price.id === currentValue.id);
            return previousValue || !!(priceById?.oneTime || false);
        }, false
    );
}

export function getSolutionNamesFromPrices(rawPrices: BEStripe.Price[], products: Product[]) {
    return Array.from(
        rawPrices.reduce<Set<ProductIdType | "">>(
            (previousValue, currentValue) => {
                const productById = getProductById(currentValue?.product as string || "", products || []);
                previousValue.add(productById?.solution || "");
                return previousValue;
            }, new Set()
        ),
    ).filter(value => !!value) as SolutionKey[];
}


export function getRawPricesFromInvoice(invoice: BEStripe.Invoice) {
    return invoice.lines.data.map(value => value.price).filter(value => !!value) as BEStripe.Price[];
}

export function toInvoice(invoice: Invoice, products: Product[]): Invoice {
    if (invoice.invoice && products) {
        const rawPrices = getRawPricesFromInvoice(invoice.invoice);
        return {
            ...invoice,
            timestamp: new Date(+invoice.timestamp),
            solutionKeys: getSolutionNamesFromPrices(rawPrices, products),
            productNames: getProductNamesFromPrices(rawPrices, products),
        };
    } else {
        return invoice;
    }
}

function getReferenceInvoice(invoices: BEStripe.Invoice[]): BEStripe.Invoice {
    let referenceInvoice = invoices[0];
    for (const invoice of invoices) {
        if (invoice.billing_reason === "subscription_update") {
            referenceInvoice = invoice;
        }
    }
    return referenceInvoice;
}

// function getInvoicesTotal(invoices: BEStripe.Invoice[]): number {
//     return invoices.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0);
// }

export function toPlanSubscriptionWithTotal(sub: (BEStripe.Subscription & {invoices: Stripe.Invoice[]}), products: Product[], customer: Customer): PlanSubscriptionWithTotal {
    const rawPrices = getRawPricesFromInvoice(sub.invoices[0]);
    const productNames = getProductNamesFromPrices(rawPrices, products);
    const solutions = getSolutionNamesFromPrices(rawPrices, products);
    const rawNonAddonPrices = rawPrices.filter(raw => !raw.metadata.addon && !!raw.recurring);
    const prices: AnyPrice[] = [...getAddons(rawPrices, true), ...getLevelPrices(rawNonAddonPrices, true), ...getPackagePrices(rawNonAddonPrices, true), ...getConsumptionPrices(rawNonAddonPrices, true)];
    const referenceInvoice = getReferenceInvoice(sub.invoices);
    const definitiveTotal = referenceInvoice.total / 100;
    const tax = (referenceInvoice.tax || 0) / 100;
    const oneTimeFromPrices = getOneTimeFromPrices(rawPrices, products);
    const status = sub.status === "active" && !oneTimeFromPrices && sub.cancel_at_period_end ? "canceled" : sub.status;
    return {
        id: sub.id,
        created: new Date(sub.created * 1000),
        cancelAt: (sub.status === PLAN_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED || sub.status === PLAN_SUBSCRIPTION_STATUS.INCOMPLETE) ? undefined : (sub.cancel_at && new Date(sub.cancel_at * 1000)) || undefined,
        canceledAt: sub.status === PLAN_SUBSCRIPTION_STATUS.CANCELED ? (sub.canceled_at && new Date(sub.canceled_at * 1000)) || undefined : undefined,
        startDate: (sub.status === PLAN_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED || sub.status === PLAN_SUBSCRIPTION_STATUS.INCOMPLETE) ? undefined : (sub.start_date && new Date(sub.start_date * 1000)) || undefined,
        status,
        prices,
        productNames,
        solutionKeys: solutions,
        total: definitiveTotal,
        customer: customer,
        tax,
        discount: sub.discount || undefined,
        cancelAtPeriodEnd: sub.cancel_at_period_end,
        nextRenew: sub.current_period_end ? new Date(sub.current_period_end * 1000) : undefined,
        oneTime: oneTimeFromPrices,
    };
}

export function toCustomerConsumptionStats(obj: any): CustomerConsumptionStats {
    return {
        customerId: obj.customerId,
        customerEmail: obj.customerEmail,
        customerBillingEmail: obj.customerBillingEmail,
        partner: obj.partner,
        availability: obj.availability.map((a: any) => ({
            solution: a.solution,
            productInternalId: a.productInternalId,
            productId: a.productId,
            current: a.current,
            historical: a.historical,
            activeSubs: a.activeSubs.map((s: any) => ({
                id: s.id,
                cancel_at: s.cancel_at ? new Date(s.cancel_at * 1000) : null,
                current_period_end: new Date(s.current_period_end * 1000),
                current_period_start: new Date(s.current_period_start * 1000),
            } satisfies SubscriptionExtract)),
        } satisfies ProductAvailability)),
    };
}
