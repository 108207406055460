import {Product} from "../types/products";
import {addLinkedToProduct, toProduct, toProductMap} from "./dto/products";
import {apiCall, HttpMethod} from "./index";
import Stripe from "stripe";

export async function getProducts(all: boolean): Promise<Product[]> {
    const res = (await apiCall({
        urlComponent: "/products",
        publicCall: true,
        query: {
            all,
        },
    }));
    const rawProducts = await res.json();
    const filteredProducts = rawProducts.map(toProduct).filter((p: Product | undefined) => !!p);
    const productMap = toProductMap(filteredProducts);

    for (const rawProduct of rawProducts) {
        addLinkedToProduct(rawProduct, productMap);
    }

    return filteredProducts;
    // return (await getProductsMock()).map(toProduct);
}

export async function getTaxRate(): Promise<Stripe.TaxRate> {
    const res = await apiCall({
        urlComponent: "/taxRates/default",
        publicCall: true,
    });
    return await res.json();
}

export async function getPromotionCode(code: string): Promise<Stripe.PromotionCode | undefined> {
    try {
        const res = await apiCall({
            urlComponent: `/promotionCodes/${code}`,
            method: HttpMethod.GET,
            publicCall: true,
        });
        return res.json();
    } catch (e) {
        console.log(e);
    }
}
