import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, Subscriptions} from "../components/Subscriptions";
import {RootState, ThunkDispatch} from "../types";
import {doCancelSubscription, doGetSubscriptions} from "../actions/thunks/customer";

function mapStateToProps({customer}: RootState): StateProps {
    return {
        subscriptions: customer.subscriptions,
        total: customer.totalSubscriptions,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSubscriptions: (query) => dispatch(doGetSubscriptions(query)),
        onCancelSubscription: (subscriptionId) => dispatch(doCancelSubscription(subscriptionId)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Subscriptions);