import {SolutionKey} from "./solutions";
import Stripe from "stripe";

export type ProductIdType = "analysis" | "data-api" | "detection" | "document-api" | "takedown" | "timestamp-api" | "web-forensics" | "web-intelligence" | "web-sign" | "web-trust" | "webpage-api" | "document-api-advanced" | "document-api-simple";

export const productIdOrdering: Record<ProductIdType, number> = {
    "analysis": 90,
    "data-api": 4,
    "detection": 90,
    "document-api": 1,
    "takedown": 90,
    "timestamp-api": 5,
    "web-forensics": 1,
    "web-intelligence": 0,
    "web-sign": 2,
    "web-trust": 3,
    "webpage-api": 3,
    "document-api-advanced": 0,
    "document-api-simple": 2,
}

export interface Product {
    id: ProductIdType;
    sid: string;
    icon: string;
    solution: SolutionKey;
    addons: FixedPrice[];
    prices: Price[];
    exclusive: boolean;
}

export enum BILLING_PERIOD {
    "month" = "month",
    "year" = "year",
}

export enum CURRENCY {
    /* ISO 4217 codes */
    "eur" = "eur",
}

export enum DURATION {
    "day" = "day",
    "week" = "week",
    "month" = "month",
    "year" = "year",
}

export enum PRICE_TYPE {
    "level" = "level",
    "package" = "package",
    "consumption" = "consumption",
}

export enum FIXED_PRICE_TYPE {
    "unaTantum" = "unaTantum",
    "recurrent" = "recurrent",
}

export function isFixedPriceType(type: string): type is FIXED_PRICE_TYPE {
    return Object.values(FIXED_PRICE_TYPE).includes(type as FIXED_PRICE_TYPE);
}

export function isPriceType(type: string): type is PRICE_TYPE {
    return Object.values(PRICE_TYPE).includes(type as PRICE_TYPE);
}

export interface FixedPrice {
    id: string;
    recurring: boolean;
    oneTime: Boolean;
    type: FIXED_PRICE_TYPE;
    name: string;
    optional: boolean;
    inTrial: boolean;
    price: number;
    currency: CURRENCY;

    multiplier: number;
}

export interface Price {
    id: string | string[];
    recurring: boolean;
    admin: boolean;
    oneTime: Boolean;
    type: PRICE_TYPE;
    price: number;
    duration: DURATION;
    currency: CURRENCY;
    sproductId: string;
    description?: string;
    linked?: Price[];
    multiplier: number;
}

export interface UnaTantumFixedPrice extends FixedPrice {
    type: FIXED_PRICE_TYPE.unaTantum;
}

export interface RecurrentFixedPrice extends FixedPrice {
    type: FIXED_PRICE_TYPE.recurrent;
    duration: DURATION;
}

export interface LevelPrice extends Price {
    type: PRICE_TYPE.level;
    from: number;
    fixed: number;
    fixedMultiplier: number;
}

export interface PackagePrice extends Price {
    type: PRICE_TYPE.package,
    to: number;
}

export interface ConsumptionPrice extends Price {
    type: PRICE_TYPE.consumption;
}

export function priceTotal(price?: Price | Price[]): number {
    if (Array.isArray(price)) {
        return price.reduce((acc, p) => acc + priceTotal(p), 0);
    } else if (price) {
        let result = price.price + (price.description === "freeTrial" ? 0 : priceTotal(price.linked));
        switch (price.type) {
            case PRICE_TYPE.level:
                result += (price as LevelPrice).fixed;
                break
        }
        return result;
    } else {
        return 0;
    }
}

export function priceIsFreeTrial(price?: Price | Price[]): boolean {
    if (!price) {
        return false;
    }
    if (!Array.isArray(price)) {
        price = [price];
    }
    return price.some((p) => p.description === "freeTrial") && priceTotal(price) === 0;
}

export function priceIdToString(price: Price): string {
    if (Array.isArray(price.id)) {
        return price.id.join(",");
    } else {
        return price.id;
    }
}

export enum SubscriptionError {
    NOT_EXISTENT = "NOT_EXISTENT",
    RESPONSE_ERROR = "RESPONSE_ERROR",
    PAYMENT_ERROR = "PAYMENT_ERROR",
    PROCESSING_PAYMENT = "PROCESSING_PAYMENT",
}

export interface PaymentIntentResult {
    id: string;
    clientSecret: string;
}

export type AnyPrice = (Price | FixedPrice);
export type CartType = { [key: string]: AnyPrice[] };

export interface ExclusivePrices {
    [key: string]: {
        price: Price;
        subscriptionId: string;
        modifiable: boolean;
    };
}

export interface ProductState {
    exclusives?: ExclusivePrices;
    products?: Product[];
    taxRates?: Stripe.TaxRate;
    selectedSolution?: SolutionKey;
    cart: CartType;
    cartLoading: boolean;
    cartError: boolean;
    promotion?: Stripe.PromotionCode;
    cartUUID?: string;
    oldCart?: CartType;
    subscriptionErrors?: Map<string, SubscriptionError>;
}
