import {connect} from "react-redux";
import {DispatchProps, Home, InnerProps, StateProps} from "../components/Home";
import {RootState, ThunkDispatch} from "../types";
import {doGetProducts} from "../actions/thunks/products";
import {setAlert} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";

function mapStateToProps({i18n, product}: RootState): StateProps {
    return {
        locale: i18n.locale,
        products: product.products,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetProducts: () => {
            dispatch(doGetProducts()).catch((error) => {
                console.error(error);
                dispatch(setAlert(I18n.t("error.getProducts"), "error"));
            });
        },
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Home);
