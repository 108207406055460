import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {CustomerState, PLAN_SUBSCRIPTION_STATUS} from "../types/customer";
import {
    canceledSubscription,
    gotBillingInfo,
    gotConsumptionStats,
    gotCustomer,
    gotInvoices,
    gotPaymentInfo,
    gotSubscriptions,
    savedBillingInfo,
    setSubscribing
} from "../actions/customer";

const initialState: CustomerState = {
    totalSubscriptions: 0,
    totalInvoices: 0,
    subscribing: false,
};

export const customerReducer = createReducer(initialState)
    .handleAction(gotCustomer, (state, action) => update(state, {
        customer: {$set: action.payload},
    }))
    .handleAction(gotBillingInfo, (state, action) => update(state, {
        billingInfo: {$set: action.payload},
    }))
    .handleAction(savedBillingInfo, (state, action) => update(state, {
        billingInfo: {$set: action.payload},
    }))
    .handleAction(gotPaymentInfo, (state, action) => update(state, {
        paymentInfo: {$set: action.payload},
    }))
    .handleAction(gotInvoices, (state, action) => update(state, {
        invoices: {$set: action.payload?.items},
        totalInvoices: {$set: action.payload?.total || 0},
    }))
    .handleAction(gotSubscriptions, (state, action) => update(state, {
        subscriptions: {$set: action.payload.subscriptions},
        totalSubscriptions: {$set: action.payload.total},
    }))
    .handleAction(canceledSubscription, (state, action) => {
        const index = state.subscriptions ? state.subscriptions.findIndex((value) => value.id === action.payload.id) : -1;
        if (index !== -1) {
            return update(state, {
                subscriptions: {
                    [index]: {
                        cancelAtPeriodEnd: {$set: action.payload.cancel_at_period_end},
                        cancelAt: {$set: (action.payload.status === PLAN_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED || action.payload.status === PLAN_SUBSCRIPTION_STATUS.INCOMPLETE) ? undefined : (action.payload.cancel_at && new Date(action.payload.cancel_at * 1000)) || undefined},
                    }
                }
            })
        } else {
            return state;
        }
    })
    .handleAction(setSubscribing, (state, action) => update(state, {
        subscribing: {$set: action.payload},
    }))
    .handleAction(gotConsumptionStats, (state, action) => update(state, {
        consumptionStats: {$set: action.payload.stats},
        consumptionStatsNext: {$set: action.payload.next},
    }))
;