import React, {Fragment, ReactChild, useEffect} from "react";
import {
    ActionType,
    Column,
    ColumnType,
    FixedSelectionFilter,
    GetParams,
    KCard,
    KContainer,
    KTableLoader,
    Table
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {I18n, Localize, Translate} from "react-redux-i18n";
import {Invoice} from "../types/customer";
import {isFiltered, tableGetParams} from "../utils/commons";

export interface StateProps {
    invoices?: Invoice[];
    total: number;
}

export interface DispatchProps {
    onGetInvoices: (query: GetParams) => Promise<void>;
    onDownloadInvoice: (id: number) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Invoices: React.FC<Props> = ({total, invoices, onDownloadInvoice, onGetInvoices}) => {
    const tableId = "customerInvoices";

    useEffect(() => {
        if (!invoices) {
            onGetInvoices(tableGetParams(tableId)).catch(console.error);
        }
    }, [invoices, onGetInvoices]);

    const actions: ActionType<Invoice>[] = [
        {
            name: <><i className="fal fa-download action-icon"/> <Translate value="generic.download"/></>,
            handler: (invoice) => onDownloadInvoice(invoice.id),
        }
    ];

    return (
        <KContainer>
            <Row>
                <Col md={10} sm={12}>
                    <KCard header={<Translate value={`user.invoices`}/>}>
                        {(!invoices) ? (
                            <KTableLoader/>
                        ) : (invoices.length === 0 && !isFiltered(tableId)) ? (
                            <h6><Translate value="user.invoicesEmpty" dangerousHTML={true}/></h6>
                        ) : (
                            <Table
                                data={invoices}
                                id={tableId}
                                loaderFunc={(q: GetParams) => onGetInvoices(q)}
                                total_count={total}
                                keyField={"id"}
                                filterDefinition={[
                                    new FixedSelectionFilter("type", <Translate value="user.invoiceTable.type"/>, [
                                        {value: "NDC", label: I18n.t("user.invoiceType.NDC")},
                                        {value: "FAT", label: I18n.t("user.invoiceType.FAT")},
                                    ])
                                ]}
                            >
                                <Column colid={"id"} visible={false} type={ColumnType.GENERIC} name="user.invoicesTable.id" sort="id" colspan={1} classes="text-start" />
                                <Column colid={"solution"} type={ColumnType.GENERIC} name={`user.invoicesTable.solution`} sort="productNames" colspan={2} classes="text-start"
                                        render={(datum: object) => {
                                            let pswt = datum as Invoice;
                                            let names = pswt.solutionKeys.length ? pswt.solutionKeys : pswt.productNames;
                                            return names.map((value, index) => (
                                                <Fragment key={index}>
                                                    <Translate value={
                                                        `${value}.title` as any
                                                    }/>{index === names.length - 1 ? null : <>, &nbsp;</>}
                                                </Fragment>
                                            )) as unknown as ReactChild;
                                        }}
                                />
                                {/*<Column colid={"sdiAnno"} type={ColumnType.GENERIC} name={"user.invoicesTable.year"} sort="sdiAnno" colspan={1}/>*/}
                                <Column colid={"type"} type={ColumnType.GENERIC} name={"user.invoicesTable.type"} sort="type" colspan={1}
                                        render={(datum: object) => (<Translate value={`user.invoiceType.${(datum as Invoice).type}` as any}/>)}
                                />
                                <Column colid={"timestamp"} type={ColumnType.DATE} name={"user.invoicesTable.date"} sort="timestamp" colspan={2}/>
                                <Column colid={"netTotal"} type={ColumnType.GENERIC} name={"user.invoicesTable.net"} sort="netTotal" colspan={2}
                                        render={(datum: object) => (
                                            <Localize
                                                value={+(datum as Invoice).netTotal}
                                                options={{style: "currency", currency: "EUR"}}
                                            />
                                        )}
                                />
                                {/*<Column colid={"taxTotal"} type={ColumnType.GENERIC} name={"user.invoicesTable.tax"} sort="taxTotal" colspan={2}*/}
                                {/*        render={(datum: object) => (*/}
                                {/*            <Localize*/}
                                {/*                value={+(datum as Invoice).taxTotal}*/}
                                {/*                options={{style: "currency", currency: "EUR"}}*/}
                                {/*            />*/}
                                {/*        )}*/}
                                {/*/>*/}
                                {/*<Column colid={"grandTotal"} type={ColumnType.GENERIC} name={"user.invoicesTable.total"} sort="grandTotal" colspan={2}*/}
                                {/*        render={(datum: object) => (*/}
                                {/*            <Localize*/}
                                {/*                value={+(datum as Invoice).grandTotal}*/}
                                {/*                options={{style: "currency", currency: "EUR"}}*/}
                                {/*            />*/}
                                {/*        )}*/}
                                {/*/>*/}
                                <Column colid={"actions"} type={ColumnType.ACTIONS} actions={actions} classes="text-end" colspan={2}/>
                            </Table>
                        )}
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    );
}
