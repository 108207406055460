import React, {Fragment, ReactChild, useEffect} from "react";
import {PLAN_SUBSCRIPTION_STATUS, PlanSubscriptionWithTotal} from "../types/customer";
import {
    ActionType,
    Column,
    ColumnType,
    FixedSelectionFilter,
    GetParams,
    KCard,
    KContainer,
    KTableLoader,
    Table,
    tooltipate
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {I18n, Localize, Translate} from "react-redux-i18n";
import {isEndStatus, isFiltered, tableGetParams} from "../utils/commons";
import {SolutionKey, SOLUTIONS} from "../types/solutions";

export interface StateProps {
    subscriptions?: PlanSubscriptionWithTotal[],
    total: number;
}

export interface DispatchProps {
    onGetSubscriptions: (query: GetParams) => Promise<void>;
    onCancelSubscription: (subscriptionId: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Subscriptions: React.FC<Props> = ({onCancelSubscription, subscriptions, total, onGetSubscriptions}) => {
    const tableId = "customerSubscriptions";

    useEffect(() => {
        if (!subscriptions) {
            onGetSubscriptions(tableGetParams(tableId)).catch(console.error);
        }
    }, [onGetSubscriptions, subscriptions]);

    const actions: ActionType<PlanSubscriptionWithTotal>[] = [
        {
            name: <span className="text-danger"><i className="fal fa-times action-icon"/> <Translate value="user.subsTable.cancel"/></span>,
            handler: async (datum: PlanSubscriptionWithTotal, ) => onCancelSubscription(datum.id),
            confirmation: true,
            shouldRender: (datum: PlanSubscriptionWithTotal) => !datum.cancelAtPeriodEnd && !isEndStatus(datum.status),
        },
        {
            name: <span><i className="fal fa-angle-double-right action-icon"/> <Translate value="user.subsTable.access"/></span>,
            handler: async (datum: PlanSubscriptionWithTotal, ) => {window.open(SOLUTIONS[datum.solutionKeys[0] as SolutionKey]?.link, "_blank");},
        },
    ];

    return (
        <KContainer>
            <Row>
                <Col md={10} sm={12}>
                    <KCard header={<Translate value={`user.subscriptions`}/>}>
                        {(!subscriptions) ? (
                            <KTableLoader/>
                        ) : (subscriptions.length === 0 && !isFiltered(tableId)) ? (
                            <h6><Translate value="user.subscriptionsEmpty" dangerousHTML={true}/></h6>
                        ) : (
                            <Table
                                data={subscriptions}
                                id={tableId}
                                loaderFunc={(q: GetParams) => onGetSubscriptions(q)}
                                total_count={total}
                                keyField={"id"}
                                filterDefinition={[
                                    new FixedSelectionFilter("status", <Translate value={"user.subsTable.status"}/>,  Object.values(PLAN_SUBSCRIPTION_STATUS).map(value => ({
                                        value,
                                        label: I18n.t(`user.status.${value}`),
                                    }))),
                                ]}
                            >
                                <Column colid={"id"} visible={false} type={ColumnType.GENERIC} name="user.subsTable.id" sort="id" colspan={2} classes="text-start" />
                                <Column colid={"productNames"} type={ColumnType.GENERIC} name={`user.subsTable.product`} sort="productNames" colspan={2} classes="text-start"
                                        render={(datum: object) => {
                                            let pswt = datum as PlanSubscriptionWithTotal;
                                            let names = pswt.solutionKeys.length ? pswt.solutionKeys : pswt.productNames;
                                            return names.map((value, index) => (
                                                <Fragment key={index}>
                                                    <Translate value={
                                                        `${value}.title` as any
                                                    }/>{index === names.length - 1 ? null : <>, &nbsp;</>}
                                                </Fragment>
                                            )) as unknown as ReactChild;
                                        }}
                                />
                                <Column colid={"created"} type={ColumnType.DATE} name="user.subsTable.created" sort="created" colspan={1} classes="text-start"/>
                                {/*<Column colid={"startDate"} type={ColumnType.DATE} name="user.subsTable.startDate" sort="startDate" colspan={1} classes="text-left"/>*/}
                                <Column colid={"cancelAt"} type={ColumnType.GENERIC} name="user.subsTable.cancelAt" sort={"cancelAt"} colspan={1} classes="text-start"
                                        render={(datum: PlanSubscriptionWithTotal) => {
                                            if (datum.cancelAtPeriodEnd) {
                                                if (datum.cancelAt) {
                                                    if (datum.oneTime) {
                                                        return <Localize value={datum.cancelAt} dateFormat={"kptable.date.short"}/>;
                                                    } else {
                                                        return tooltipate(<Localize value={datum.cancelAt} dateFormat={"kptable.date.short"}/>, <Translate value="user.subsTable.canceledTooltip"/>, undefined, true);
                                                    }
                                                } else {
                                                    return <></>;
                                                }
                                            } else {
                                                if (datum.cancelAt || datum.status === "canceled") {
                                                    return tooltipate(<Localize value={(datum.cancelAt || datum.nextRenew) as any} dateFormat={"kptable.date.short"}/>, <Translate value="user.subsTable.canceledTooltip"/>, undefined, true);
                                                } else if (datum.nextRenew) {
                                                    return tooltipate(<Localize value={datum.nextRenew} dateFormat={"kptable.date.short"}/>, <Translate value="user.subsTable.renewTooltip"/>, undefined, true);
                                                } else {
                                                    return <></>;
                                                }
                                            }
                                        }}
                                />
                                <Column colid={"cancelAtPeriodEnd"} type={ColumnType.GENERIC} name="user.subsTable.autoRenewal" colspan={1} sort="cancelAtPeriodEnd" classes="text-center"
                                        render={(datum: PlanSubscriptionWithTotal) => <Translate value={(datum.cancelAtPeriodEnd || datum.status === "canceled") ? "generic.no" : "generic.yes"} />}
                                />
                                {/*<Column colid={"canceledAt"} type={ColumnType.DATE} name="user.subsTable.canceledAt" sort="canceledAt" colspan={1} classes="text-left"/>*/}
                                <Column colid={"total"} type={ColumnType.GENERIC} name="user.subsTable.total" sort="total" colspan={1} classes="text-end"
                                        render={(datum: PlanSubscriptionWithTotal) => (
                                            <Localize
                                                value={datum.total}
                                                options={{style: "currency", currency: "EUR"}}
                                            />
                                        )}
                                />
                                <Column colid={"status"} type={ColumnType.GENERIC} name="user.subsTable.status" sort="status" colspan={1} classes="text-start"
                                        render={(datum: object) => <Translate value={`user.status.${(datum as PlanSubscriptionWithTotal).status}` as any}/>}
                                />
                                <Column colid={"actions"} type={ColumnType.ACTIONS} actions={actions} classes="text-end" colspan={2}/>
                            </Table>
                        )}
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    );
}
