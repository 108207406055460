import {ProductIdType} from "../types/products";

export const BE_ENDPOINT = process.env.REACT_APP_BE_ENDPOINT;

export type ENV_TYPE = "production" | "staging" | "development";
export const ENVIRONMENT: ENV_TYPE = process.env.NODE_ENV as ENV_TYPE || "development";

export const ENV = process.env.REACT_APP_ENV;

export const AUTH0_DOMAIN = "auth.kopjra.com";
export const AUTH0_CLIENT_ID = ENV === "stag" ? "nh1VayK0cpXKJOBUnbrd6a2rNFNXM6Jg" : "qPiJnw5aIpfwg5uHuUVVcQV9MwCxqJ31";
export const AUTH0_AUDIENCE = "https://api.gateway.kopjra.com"; // Since Auth0 doesn't support multiple audiences, we have a common API audience

export const CART_VERSION = "1.0.0";

export const CART_KEY = `${ENVIRONMENT}_CART_${CART_VERSION}_$$_STORE_KOPJRA`;

export const IVA = 0.22;

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export const ENV_Coupons: Record<ENV_TYPE, Partial<Record<ProductIdType, string>>> = {
    production: {
        // "web-forensics": "CPEUR0CRED1RNPDKT",
    },
    staging: {
        // "web-forensics": "CPEUR0CRED1RNPDKT",
    },
    development: {
        // "web-forensics": "CPEUR0CRED1RNPDKT",
    }
}
