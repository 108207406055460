import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {
    CartType,
    ConsumptionPrice,
    DURATION,
    FIXED_PRICE_TYPE,
    FixedPrice,
    isFixedPriceType,
    isPriceType,
    LevelPrice,
    PackagePrice,
    Price,
    PRICE_TYPE,
    priceIsFreeTrial,
    priceTotal,
    Product,
    RecurrentFixedPrice
} from "../types/products";
import {KCheck, KLabel, KSelect, KSpace} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {CartPriceOperation, getDurations, getTranslationElement, modifyCart, pos} from "../utils/commons";
import {v4} from "uuid";

export interface PlanDetailProps {
    id: string;
    product: Product;
    cartPiece: CartType;
    readonly: boolean;
    exclusivePrice?: Price;
    onChange: (cartPiece: CartType) => void;
}

type PriceGather = {prices: Price[]; durations: DURATION[];};

export const PlanDetail: React.FC<PlanDetailProps> = ({exclusivePrice, readonly, id, onChange, product, cartPiece}) => {
    let addons = product.addons
        .sort((a, b) => !a.optional && b.optional ? -1 : 1);

    const groupedPrices: PriceGather[] = useMemo(() => {
        const prices = [...product.prices.filter((p) => (p.id !== exclusivePrice?.id || priceTotal(p) !== 0) && !p.admin)];
        const gather: PriceGather = {
            prices: prices.sort((a, b) => priceTotal(a) - priceTotal(b)),
            durations: getDurations(prices),
        };
        return [gather];
    }, [exclusivePrice, product.prices]);

    const setMandatoryAddons = useCallback((duration?: DURATION) => {
        const toAdd: CartPriceOperation[] = [];
        const prices = cartPiece[id]?.filter(p => isPriceType(p.type)) as Price[];
        for (const fp of product.addons) {
            if (!fp.optional && !priceIsFreeTrial(prices) &&
                (!duration || fp.type === FIXED_PRICE_TYPE.unaTantum ||
                    (fp.type === FIXED_PRICE_TYPE.recurrent && (fp as RecurrentFixedPrice).duration === duration)
                )) {
                toAdd.push({price: fp, type: fp.type, op: "add"});
            }
        }
        if (toAdd.length > 0) {
            onChange(modifyCart(cartPiece, id, toAdd));
        }
    }, [cartPiece, id, onChange, product.addons]);

    useEffect(() => {
        setMandatoryAddons(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [duration, setDuration] = useState<DURATION | undefined>();

    useEffect(() => {
        if (exclusivePrice) {
            setMandatoryAddons(duration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration, exclusivePrice]);

    function renderDurations(gprice: PriceGather, dur: DURATION | undefined, selectedPrice: Price | undefined, addonSelectedPrices: FixedPrice[], setDuration: (value: (((prevState: (DURATION | undefined)) => (DURATION | undefined)) | DURATION | undefined)) => void) {
        return gprice.durations.length > 1 ? (
                <Row>
                    <Col lg={3} md={4}>
                        <KLabel dark={readonly} text={<Translate value={`${product.id}.recurrence` as any}/>}/>
                    </Col>
                    <Col lg={9} md={8}>
                        {gprice.durations.map((value, dindex) => (
                            <KCheck key={dindex} id={`${product.id}-${value}`} label={<Translate value={`generic.recurrence.${value}`}/>}
                                    type={"radio"} required={true} name={`${product.id}-duration`} inline={true} value={value}
                                    checked={dur === value}
                                    disabled={readonly}
                                    onChange={(event) => { if (event.currentTarget.checked) {
                                        setDuration(value);
                                        if (selectedPrice) {
                                            onChange(modifyCart(cartPiece, id, [{price: selectedPrice, op: "remove", type: selectedPrice.type}]))
                                        }
                                        addonSelectedPrices.forEach((p) => onChange(modifyCart(cartPiece, id, [{price: p, op: "remove", type: p.type}])));
                                    }}}/>
                        ))}
                    </Col>
                </Row>
            ) : null;
    }

    function chooseDuration(priceDuration: DURATION | undefined, stateDuration: DURATION | undefined, defaultDuration: DURATION | undefined) {
        if (priceDuration && stateDuration) {
            return (priceDuration !== stateDuration) ? stateDuration : priceDuration;
        } else if (priceDuration) {
            return priceDuration;
        } else if (stateDuration) {
            return stateDuration;
        } else {
            return defaultDuration;
        }
    }

    let selectedPrice: Price | undefined;
    return (
        <>
            <KSpace spaces={2}/>
            {groupedPrices.map((gprice, index) => {
                const price = cartPiece[id]?.find(p => isPriceType(p.type)) as Price | undefined;
                selectedPrice = gprice.prices.length === 1 ? (gprice.prices[0].id === price?.id ? gprice.prices[0] : undefined) : gprice.prices.find((p) => JSON.stringify(p.id) === JSON.stringify(price?.id));
                if (duration && selectedPrice?.duration !== duration) {
                    selectedPrice = undefined;
                }
                const lduration = chooseDuration(selectedPrice?.duration, duration, gprice.durations.length > 0 ? gprice.durations[0] : undefined);
                const durationPrices = gprice.prices.filter(value => value.duration === lduration);
                const addonPrices = cartPiece[id]?.filter(p => isFixedPriceType(p.type)) as FixedPrice[] | undefined
                const addonSelectedPrices: FixedPrice[] = [];
                addons = addons.filter((addon) => {
                    if (addon.type === FIXED_PRICE_TYPE.unaTantum || (addon as RecurrentFixedPrice).duration === lduration) {
                        if (addonPrices?.find((p) => p.id === addon.id)) {
                            addonSelectedPrices.push(addon);
                        }
                        return true;
                    } else {
                        return false;
                    }
                });
                if (!selectedPrice && !readonly) {
                    onChange(modifyCart(cartPiece, id, [{price: durationPrices[0], op: "add", type: durationPrices[0].type}]));
                    addonSelectedPrices.forEach((p) => {
                        if (!p.optional && (priceTotal(durationPrices[0]) === 0 && durationPrices[0]?.description === "freeTrial")) {
                            onChange(modifyCart(cartPiece, id, [{
                                price: p,
                                op: "remove",
                                type: p.type
                            }]));
                        }
                    });
                }
                // eslint-disable-next-line array-callback-return
                const selectOptions: {label: JSX.Element, value: Price}[] = durationPrices.map((price) => {
                    switch (price.type) {
                        case PRICE_TYPE.level:
                            const lprice = price as LevelPrice;
                            return {
                                value: price,
                                label: getTranslationElement(`${product.id}.${PRICE_TYPE.level}.item`, `${product.id}.${PRICE_TYPE.level}.${price.description}.${price.duration}`, {
                                    count: I18n.l(lprice.from),
                                    fixed: I18n.l(lprice.fixed * lprice.fixedMultiplier, {style: "currency", currency: price.currency.toUpperCase()}),
                                    price: I18n.l(price.price * price.multiplier, {style: "currency", currency: price.currency.toUpperCase()}),
                                    duration: I18n.t(`generic.duration.${price.duration}` as any),
                                    dangerousHTML: true,
                                }, price.linked),
                            }
                        case PRICE_TYPE.package:
                            const pprice = price as PackagePrice;
                            return {
                                value: price,
                                label: getTranslationElement(`${product.id}.${PRICE_TYPE.package}.item`, `${product.id}.${PRICE_TYPE.package}.${price.description}.${price.duration}`, {
                                    count: I18n.l(pprice.to),
                                    price: I18n.l(price.price * price.multiplier, {style: "currency", currency: price.currency.toUpperCase()}),
                                    duration: I18n.t(`generic.duration.${price.duration}` as any),
                                    dangerousHTML: true,
                                }, price.linked),
                            }
                        case PRICE_TYPE.consumption:
                            const cprice = price as ConsumptionPrice;
                            return {
                                value: price,
                                label: getTranslationElement(`${product.id}.${PRICE_TYPE.consumption}.item`, `${product.id}.${PRICE_TYPE.package}.${cprice.description}.${cprice.duration}`, {
                                    price: I18n.l(cprice.price * cprice.multiplier, {style: "currency", currency: cprice.currency.toUpperCase()}),
                                    duration: I18n.t(`generic.duration.${cprice.duration}` as any),
                                    dangerousHTML: true,
                                }, price.linked),
                            }
                    }
                });
                return (
                    <Fragment key={`grouped-${index}`}>
                        {renderDurations(gprice, lduration, selectedPrice, addonSelectedPrices, setDuration)}
                        <Row>
                            <Col lg={3} md={4}>
                                <KLabel dark={readonly} text={<Translate value={`${product.id}.planLabel` as any}/>}/>
                            </Col>
                            <Col lg={9} md={8}>
                                <KSelect<Price>
                                    id={`priceSelect_${id}`}
                                    disabled={readonly}
                                    name={`${id}_price`}
                                    options={selectOptions}
                                    key={`select_${price ? price.id : "noselection"}${!selectedPrice ? v4() : ""}`}
                                    placeholder={<Translate value="generic.select"/>}
                                    value={selectedPrice}
                                    required={true}
                                    onChange={(value) => {
                                        let price: Price | undefined;
                                        if (Array.isArray(value)) {
                                            price = value[0]
                                        } else {
                                            price = value;
                                        }
                                        if (price) {
                                            onChange(modifyCart(cartPiece, id, [{
                                                price,
                                                op: "add",
                                                type: price.type
                                            }]));
                                            setMandatoryAddons(duration);
                                            addonSelectedPrices.forEach((p) => {
                                                if (p.optional || priceIsFreeTrial(price)) {
                                                    onChange(modifyCart(cartPiece, id, [{
                                                        price: p,
                                                        op: "remove",
                                                        type: p.type
                                                    }]));
                                                }
                                            });
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </Fragment>
                );
            })}
            {addons.length ? (
                <Row>
                    <Col lg={3} md={4}>
                        <KLabel dark={readonly} text={<Translate value="productDetail.addon"/>}/>
                    </Col>
                    <Col lg={9} md={8}>
                        {addons.filter((addon) => (addon.optional || !priceIsFreeTrial(selectedPrice)) && (!priceIsFreeTrial(selectedPrice) || !addon.inTrial)).map((addon, index) => (
                            <Row key={index}>
                                <Col>
                                    <KCheck id={`${id}_${addon.name}`}
                                            label={<Translate
                                                value={`${product.id}.addons.${addon.name}` as any}
                                                price={I18n.l(addon.price * addon.multiplier, {style: "currency", currency: addon.currency.toUpperCase()})}
                                                duration={I18n.t(`generic.duration.${(addon.type === FIXED_PRICE_TYPE.recurrent) ? (addon as RecurrentFixedPrice).duration : undefined}` as any)}
                                                dangerousHTML={true}
                                            />}
                                            name={`${id}_${addon.name}`}
                                            type={"checkbox"}
                                            checked={!addon.optional ? true : ( pos(cartPiece[id] || [], addon) !== -1 )}
                                            disabled={!addon.optional || readonly}
                                            onChange={(event) => onChange(modifyCart(cartPiece, id, event.currentTarget.checked ? [{price: addon, op: "add", type: addon.type}] : [{price: addon, op: "remove", type: addon.type}]))}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            ) : null}
            {selectedPrice ? (
                <Row>
                    <Col lg={3} md={4}>
                        <KLabel text={<Translate value="productDetail.duration"/>}/>
                    </Col>
                    <Col lg={9} md={8}>
                        {getTranslationElement(`${product.id}.${selectedPrice.type}.durationDetail`, `${product.id}.${selectedPrice.type}.${selectedPrice.description}.conditions`, {
                            duration: I18n.t(`generic.duration.${selectedPrice.duration}` as any),
                            dangerousHTML: true,
                        }, selectedPrice.linked)}
                    </Col>
                </Row>
            ) : null}
        </>
    );
}
