import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {CartType, ExclusivePrices, Product, productIdOrdering} from "../types/products";
import {SolutionKey, SOLUTIONS} from "../types/solutions";
import {
    IMAGES,
    KButton,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KContainer,
    KErrorPage,
    KForm,
    KSpace,
    KSpinner,
    KSpinnerSize
} from "@kopjra/uikit";
import {Localize, Translate} from "react-redux-i18n";
import {Col, Row} from "react-bootstrap";
import {ProductDetail} from "./ProductDetail";
import {computeCartTotal, decomp, getProductsFromSolution} from "../utils/commons";
import {useQuery} from "../utils/router";
import {useAuth0} from "@auth0/auth0-react";

export interface StateProps {
    selected?: SolutionKey;
    products?: Product[];
    exclusives?: ExclusivePrices;
    locale: string;
    cart: CartType;
}

export interface DispatchProps {
    onSelectSolution: (solution: SolutionKey, group?: string) => Promise<void>;
    onAddToCart: (cartPiece: CartType) => Promise<void>;
    onUpdateInCart: (cartPiece: CartType) => Promise<void>;
    onGetProductExclusive: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const SolutionDetail: React.FC<Props> = ({exclusives, onGetProductExclusive, products, selected, onSelectSolution, locale, onAddToCart, cart, onUpdateInCart}) => {
    const { solution } = useParams<{solution: SolutionKey}>();
    const { isAuthenticated } = useAuth0();
    const cartId = useQuery().get("cartId");
    const group = useQuery().get("group") || undefined;
    const initialPrices = cartId ? cart[cartId] : undefined;
    const [cartPiece, setCartPiece] = useState<CartType>((initialPrices && cartId) ? { [cartId]: initialPrices } : {});
    const [submitting, setSubmitting] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if ((!selected || selected !== solution) && solution) {
            onSelectSolution(solution, group).catch(console.log);
        }
    }, [selected, solution, onSelectSolution, group]);

    useEffect(() => {
        if (!exclusives && isAuthenticated) {
            onGetProductExclusive().catch(console.log);
        }
    }, [exclusives, isAuthenticated, onGetProductExclusive]);

    const selectedProducts = useMemo(
        () => getProductsFromSolution(solution, products).filter(p => !cartId || !cart[cartId] || decomp(cartId).id === p.id),
        [cart, cartId, products, solution],
    );

    const value = computeCartTotal(cartPiece);

    if (Object.keys(SOLUTIONS).indexOf(solution || "") === -1) {
        return <KErrorPage title={<Translate value="error.page"/>} code={"404"}
                           message={<Translate value="error.notFound"/>}/>;
    } else {
        return (
            <KContainer marginTop={75} fluid={"md"}>
                {solution !== selectedProducts[0]?.id || selectedProducts.length > 1 ? (
                    <>
                        <h5><img src={IMAGES[solution || "kopjra"].still.large.default} height={100}
                                 alt={`${solution} logo`}/></h5>
                        <h3><Translate value={`${solution}.title` as any}/></h3>
                        <h4><Translate value={`${solution}.subtitle` as any}/></h4>
                        <KSpace spaces={2}/>
                    </>
                ) : null}
                {selected ? (
                    <KForm
                        onSubmit={async () => (cartId && cart[cartId]) ? onUpdateInCart(cartPiece) : onAddToCart(cartPiece)}
                        onSubmitting={(submitting) => setSubmitting(submitting)}>
                        <Row>
                            {selectedProducts.sort((a, b) => productIdOrdering[a.id] < productIdOrdering[b.id] ? -1 : 1).map((product, index) => {
                                const editId = !!(cartId && cart[cartId]) ? cartId : undefined;
                                const totalProducts = selectedProducts.length;
                                return (
                                    <Fragment key={index}>
                                        {/*{index > 0 ? (index % 2 === 1 ? (*/}
                                        {/*    <KColSeparator minShowSize={KCOLSEPARATOR_SIZE.md}/>*/}
                                        {/*) : (*/}
                                        {/*    <KColSeparator forceHorizontal={true}/>*/}
                                        {/*)) : null}*/}
                                        <Col
                                            sm={12}
                                            lg={12}
                                            xl={(totalProducts > 1 && !editId) ? 10 : 7}
                                        >
                                            <KCard>
                                                <ProductDetail
                                                    product={product}
                                                    locale={locale}
                                                    cartPiece={cartPiece}
                                                    editId={editId}
                                                    totalProducts={totalProducts}
                                                    exclusives={exclusives}
                                                    directSale={product.prices.length === 0}
                                                    onChange={(cp, removeId) => {
                                                        if (removeId) {
                                                            const tempCartPiece = {...cartPiece};
                                                            delete tempCartPiece[removeId];
                                                            setCartPiece(tempCartPiece);
                                                        } else {
                                                            setCartPiece({...cartPiece, ...cp});
                                                        }
                                                    }}
                                                />
                                            </KCard>
                                        </Col>
                                    </Fragment>
                                );
                            })}
                        </Row>
                        {/*{showError ? (*/}
                        {/*    <Row>*/}
                        {/*        <Col sm={12}*/}
                        {/*             lg={12}*/}
                        {/*             xl={6}*/}
                        {/*         >*/}
                        {/*            <p className="text-danger small" style={{marginTop: -35}}><Translate value="productDetail.selectPlanError"/></p>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*) : null}*/}
                        <Row>
                            <Col xs={12} className="text-center">
                                <span className="totalLabel"><Translate value="productDetail.total"/></span>
                            </Col>
                            <Col xs={12} className="text-center">
                                <span className="totalValue"><Localize value={value[0]}
                                                                       options={{style: "currency", currency: "EUR"}}/></span>
                            </Col>
                        </Row>
                        <KSpace spaces={1}/>
                        <Row>
                            <Col md={4} lg={3} xl={3} className="text-center">
                                {submitting ? (
                                    <h2><KSpinner size={KSpinnerSize.lg}/></h2>
                                ) : (
                                    <KButton
                                        id={"solution-add-update"}
                                        text={<><i className="fal fa-shopping-cart"/> <Translate
                                            value={cartId ? "productDetail.updateCart" : "productDetail.addToCart"}/></>}
                                        type={KBUTTON_TYPE.submit}
                                        variant={KBUTTON_VARIANT.primary}
                                        fill={true}
                                    />
                                )}
                            </Col>
                        </Row>
                        <KSpace spaces={2}/>
                        <h4><a className="link" href={`https://estimator.kopjra.com/`} target="_blank"
                               rel="noopener noreferrer"><Translate value="home.contactus"/></a></h4>
                        <KSpace spaces={2}/>
                    </KForm>
                ) : null}
            </KContainer>
        );
    }
}
