import {connect} from "react-redux";
import {DispatchProps, InnerProps, OrderConfirmation, StateProps} from "../components/OrderConfirmation";
import {RootState, ThunkDispatch} from "../types";
import {resetOldCart} from "../actions/products";
import {navigate, Routes} from "../utils/router";
import {doGetCustomer} from "../actions/thunks/customer";

function mapStateToProps({product, customer}: RootState): StateProps {
    return {
        cart: product.cart,
        oldCart: product.oldCart,
        subscriptionErrors: product.subscriptionErrors,
        customer: customer.customer,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onResetOldCart: () => {dispatch(resetOldCart()); },
        onGotoCart: () => { navigate(Routes.CART, {replace: true}); },
        onGotoHome: () => { navigate(Routes.HOME, {replace: true}); },
        onGetCustomer: () => dispatch(doGetCustomer()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
