import {
    AnyPrice,
    CartType,
    DURATION,
    FIXED_PRICE_TYPE,
    FixedPrice,
    isPriceType,
    LevelPrice,
    PackagePrice,
    Price,
    PRICE_TYPE,
    Product,
    ProductIdType
} from "../types/products";
import {SolutionKey} from "../types/solutions";
import {
    extractGetCursedParamsFromState,
    extractGetParamsFromState,
    GetCursedParams,
    GetParams,
    KAlertType,
    ParamFilter,
    setAlert,
    SortingDirection
} from "@kopjra/uikit";
import {store} from "../index";
import {GetConsumptionStatsParams, GetInvoicesParams, PaymentInfo, PLAN_SUBSCRIPTION_STATUS} from "../types/customer";
import {v4} from "uuid";
import Stripe from "stripe";
import _ from "lodash";
import {I18n, Translate} from "react-redux-i18n";
import React from "react";

export function createURLSearchParams(obj: any): URLSearchParams {
    const usp = new URLSearchParams();
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined && obj[key] !== null) {
            usp.append(key, obj[key]);
        }
    }
    return usp;
}

export function removeDuplicates<T>(elems: T[], prop: keyof T) {
    const props: Set<any> = new Set();
    const result: T[] = [];

    for (const elem of elems) {
        if (!props.has(elem[prop])) {
            result.push(elem);
            props.add(elem[prop]);
        }
    }

    return result;
}

export function getFromLocalStorage<T>(key: string): T | undefined {
    const item = localStorage.getItem(key);
    if (item) {
        return JSON.parse(item) as T;
    } else {
        return undefined;
    }
}

export function setToLocalStorage<T>(key: string, obj: T | undefined): void {
    if (obj) {
        const str = JSON.stringify(obj);
        localStorage.setItem(key, str);
    } else {
        localStorage.removeItem(key);
    }
}

export type SolutionCart = {
    [key in ProductIdType]?: {
        prices: (Price | FixedPrice)[];
    };
};

export function hasAtLeastOneProduct(aIds: string[], bIds: string[]): boolean {
    for (const id of aIds) {
        if (bIds.indexOf(id) !== -1) {
            return true;
        }
    }
    return false;
}

export function validatePromoCode(promotionCode: Stripe.PromotionCode, cart: CartType): boolean {
    let ok = true;
    if (promotionCode.coupon.applies_to?.products) {
        ok = hasAtLeastOneProduct(promotionCode.coupon.applies_to?.products, Object.keys(cart).map(k => decomp(k).sid));
    }

    if (promotionCode.max_redemptions && promotionCode.max_redemptions <= promotionCode.times_redeemed) {
        ok = false;
    }

    if (!promotionCode.active) {
        ok = false;
    }

    if (promotionCode.expires_at && new Date().getTime() > promotionCode.expires_at * 1000) {
        ok = false;
    }

    const [total] = computeCartTotal(cart);
    if (promotionCode.restrictions.minimum_amount && promotionCode.restrictions.minimum_amount > total * 100) {
        ok = false;
    }
    return ok;
}

export function isPrice(p: Price | FixedPrice): p is Price {
    return Object.values(PRICE_TYPE).indexOf(p.type as any) !== -1;
}

export function isFixedPrice(p: Price | FixedPrice): p is FixedPrice {
    return Object.values(FIXED_PRICE_TYPE).indexOf(p.type as any) !== -1;
}

export function applyPromotion(total: number, promotion?: Stripe.PromotionCode | Stripe.Discount): number {
    if (promotion) {
        if (promotion.coupon.amount_off) {
            total = Math.max(0, total - (promotion.coupon.amount_off / 100));
        } else if (promotion.coupon.percent_off) {
            total = Math.max(0, total - total * promotion.coupon.percent_off / 100);
        }
    }
    return total;
}

export function computeTotal(prices: (Price | FixedPrice | undefined)[], promotion?: Stripe.PromotionCode | Stripe.Discount): [number, boolean] {
    let hasMetered = false;
    let total = prices.reduce((previousValue, currentValue) => {
        if (currentValue) {
            switch (currentValue.type) {
                case FIXED_PRICE_TYPE.recurrent:
                case FIXED_PRICE_TYPE.unaTantum:
                    return previousValue + (currentValue as FixedPrice).price;
                case PRICE_TYPE.consumption:
                    hasMetered = true;
                    return previousValue; //(currentValue as ConsumptionPrice).price;
                case PRICE_TYPE.package:
                    return previousValue + (currentValue as PackagePrice).price;
                case PRICE_TYPE.level:
                    return previousValue + (currentValue as LevelPrice).fixed;
                default:
                    return previousValue;
            }
        } else {
            return previousValue;
        }
    }, 0);
    return [applyPromotion(total, promotion), hasMetered];
}

export function computeCartTotal(cart?: CartType, promotionCode?: Stripe.PromotionCode): [number, boolean] {
    if (cart) {
        let [computedTotal, hasMetered] = computeTotal(Object.values(cart).reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []));
        return [applyPromotion(computedTotal, promotionCode), hasMetered];
    } else {
        return [0, false];
    }
}

export interface CartPriceOperation {
    op: "add" | "remove";
    type: PRICE_TYPE | FIXED_PRICE_TYPE;
    price: AnyPrice | undefined;
}

export function pos(prices: AnyPrice[], price?: AnyPrice, matchTypeFunc?: (type: string) => boolean): number {
    return prices.findIndex(value => matchTypeFunc ? matchTypeFunc(value.type) : value.id === price?.id);
}

export function modifyCart(cart: CartType, pid: string, ops: CartPriceOperation[]): CartType {
    const cartPiece: CartType = {[pid]: cart[pid] || []};
    for (const op of ops) {
        switch (op.type) {
            case PRICE_TYPE.package:
            case PRICE_TYPE.level:
            case PRICE_TYPE.consumption:
                if (op.op === "add") {
                    const index = pos(cartPiece[pid], op.price, isPriceType);
                    if (index !== -1) {
                        op.price ? cartPiece[pid].splice(index, 1, op.price) : cartPiece[pid].splice(index, 1);
                    } else {
                        op.price && cartPiece[pid].push(op.price);
                    }
                } else if (op.op === "remove") {
                    const index = pos(cartPiece[pid], op.price);
                    if (index !== -1) {
                        cartPiece[pid].splice(index, 1);
                    }
                }

                break;
            case FIXED_PRICE_TYPE.unaTantum:
            case FIXED_PRICE_TYPE.recurrent:
                if (op.op === "add") {
                    const index = pos(cartPiece[pid], op.price);
                    if (index !== -1) {
                        op.price ? cartPiece[pid].splice(index, 1, op.price) : cartPiece[pid].splice(index, 1);
                    } else {
                        op.price && cartPiece[pid].push(op.price);
                    }
                } else if (op.op === "remove") {
                    const index = pos(cartPiece[pid], op.price);
                    if (index !== -1) {
                        cartPiece[pid].splice(index, 1);
                    }
                }
                break;
        }
    }
    return cartPiece;
}

export function isEndStatus(status: Stripe.Subscription.Status): boolean {
    switch (status) {
        case PLAN_SUBSCRIPTION_STATUS.ACTIVE:
        case PLAN_SUBSCRIPTION_STATUS.INCOMPLETE:
        case PLAN_SUBSCRIPTION_STATUS.TRIALING:
            return false;
        case PLAN_SUBSCRIPTION_STATUS.CANCELED:
        case PLAN_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED:
        case PLAN_SUBSCRIPTION_STATUS.PAST_DUE:
        case PLAN_SUBSCRIPTION_STATUS.UNPAID:
            return true;
        default:
            return false;
    }
}

export function getProductsFromSolution(sk?: SolutionKey, products?: Product[]): Product[] {
    if (!products || !sk) {
        return [];
    } else {
        return products.filter(p => p.solution === sk);
    }
}

export function isVAT(vat: string): boolean {
    return vat !== "" && /^(IT|it|It|iT)?[0-9]{11}$/.test(vat);
}

export function isCF(cfin: string): boolean {
    const cf = cfin.toUpperCase();
    const cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    if (!cfReg.test(cf)) {
        return false;
    }

    const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    let s = 0;
    for( let i = 1; i <= 13; i += 2 )
        s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
    for( let i = 0; i <= 14; i += 2 )
        s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
    return s % 26 === cf.charCodeAt(15) - 'A'.charCodeAt(0);
}

export function showAlert(msg: string, type?: KAlertType) {
    store.dispatch(setAlert(msg, type));
}

export function twoDigits(n: number): string {
    return `${n <=9 ? "0" : ""}${n}`;
}

export function findDefaultPaymentMethod(paymentInfo?: PaymentInfo): Stripe.PaymentMethod | undefined{
    return paymentInfo?.paymentMethods.find(pi => pi.id === paymentInfo?.defaultPaymentMethodId);
}

export function hasValidDefaultPaymentMethod(paymentInfo?: PaymentInfo): boolean {
    const now = new Date();
    const dpi = findDefaultPaymentMethod(paymentInfo);
    return !!dpi && (dpi.type === "sepa_debit" || !(now.getFullYear() >= (dpi.card?.exp_year || 0) && now.getMonth() + 1 > (dpi.card?.exp_month || 0)));
}

export function diffCart(big: CartType, small: CartType): CartType {
    const result: CartType = {};
    for (const key of Object.keys(big)) {
        if (!small[key]) {
            result[key] = big[key];
        } else {
            for (const price of big[key]) {
                if (!small[key].find(value => value.id === price.id)) {
                    if (!result[key]) {
                        result[key] = [];
                    }
                    result[key].push(price);
                }
            }
        }
    }
    return result;
}

export function tableGetParams(id: string): GetParams {
    return extractGetParamsFromState(store.getState(), id);
}

export function tableGetCursedParams(id: string): GetCursedParams {
    return extractGetCursedParamsFromState(store.getState(), id);
}

export function isFiltered(id: string): boolean {
    return Object.keys(store.getState().kptable[id]?.filter || {}).length > 0;
}

export function createLinkToDownload(url: string, filename?: string) {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.target = "_blank";
    if (filename) {
        link.setAttribute("download", filename);
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function getDurations(prices: Price[]) {
    const sortMap = {
        day: 2,
        week: 3,
        month: 0,
        year: 1,
    }

    const durations = new Set<DURATION>();
    for (const price of prices) {
        if (price.duration) {
            durations.add(price.duration);
        }
    }

    return Array.from(durations).sort((a, b) => sortMap[a] < sortMap[b] ? -1 : 1);
}

export function downloadBlob(blob: Blob, filename: string) {
    // Create an object URL for the blob object
    const url = URL.createObjectURL(blob);

    // Create a new anchor element
    const a = document.createElement('a');

    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = url;
    a.download = filename || 'download';

    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };

    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener('click', clickHandler, false);

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function createId(product: Product): string {
    return `${product.solution}$$${product.id}$$${product.sid}$$${v4()}`;
}

export function decomp(str: string): {solution: SolutionKey, id: ProductIdType, sid: string} {
    const splitted = str.split("$$");
    return {
        solution: splitted[0] as SolutionKey,
        id: splitted[1] as ProductIdType,
        sid: splitted[2],
    };
}

export async function delay(time: number): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, time));
}

export class PollingError<T> extends Error {
    private readonly _context: T;

    constructor(context: T, msg?: string) {
        super(msg);
        this._context = context;
    }

    get context(): T {
        return this._context;
    }
}

export async function pollOnCondition<T>(func: () => Promise<T>, stopCondition: (element: T) => boolean, time: number = 3000, maxAttempts: number = 3): Promise<T> {
    let result: T;
    let count = 0;
    do {
        await delay(time);
        result = await func();
        count++;
    } while (!stopCondition(result) && count < maxAttempts);

    if (stopCondition(result)) {
        return result;
    } else {
        throw new PollingError(result);
    }
}

export function getProductById(id: string, products: Product[]): Product | undefined {
    return products.find(value => value.sid === id);
}

export function transformToInvoicesParams(query: GetParams): GetInvoicesParams {
    const result: GetInvoicesParams = {
        top: query.top,
        skip: query.skip,

    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sortBy = `${(query.direction === SortingDirection.UP) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const idPf = query.filter.find((pf: ParamFilter) => (pf.name === "type"));
        result.type = idPf?.value as string;
    }
    return result
}

export function transformToConsumptionStatsParams(query: GetCursedParams): GetConsumptionStatsParams {
    const result: GetConsumptionStatsParams = {
        limit: query.top,
        current: query.current,
        direction: query.currentDirection || "next",
    };
    if (query.filter && query.filter.length > 0) {
        const emailPf = query.filter.find((pf: ParamFilter) => (pf.name === "customerEmail"));
        result.customerEmail = emailPf?.value as string | undefined;

        const partnerPf = query.filter.find((pf: ParamFilter) => (pf.name === "partner"));
        result.partner = partnerPf?.value as string | undefined;
    }
    return result;
}

export interface Paginated<S> {
    items: S[];
    total: number;
}

export function getProductAndPrice(products: Product[], priceId: string): { product?: Product, price?: Price } {
    let product: Product | undefined;
    let price: Price | undefined;
    for (const pd of products) {
        for (const pr of pd.prices) {
            if (Array.isArray(pr.id)) {
                for (const prId of pr.id) {
                    if (prId === priceId) {
                        product = pd;
                        price = pr;
                        break;
                    }
                }
            } else {
                if (pr.id === priceId) {
                    product = pd;
                    price = pr;
                    break;
                }
            }
        }
    }
    return {product, price};
}

export function getTranslationElement(defaultKey: string, key: string, params: {[key: string]: string | number | boolean}, linked?: Price[]): JSX.Element {
    if (linked && linked[0]) {
        params.linked_price = I18n.l(linked[0].price * linked[0].multiplier, {style: "currency", currency: linked[0].currency.toUpperCase()});
    }
    if (!key) {
        return <Translate {...params} value={defaultKey as any}/>;
    } else {
        const {locale, translations} = store.getState().i18n;
        const translation = translations[locale];
        return typeof _.get(translation, key) === "string" ? <Translate {...params} value={key as any}/> : <Translate {...params} value={defaultKey as any}/>;
    }
}

export async function wait(fn: (waiting: boolean) => void, promise: Promise<any>): Promise<any> {
    try {
        fn(true);
        return await promise;
    } finally {
        fn(false);
    }
}
