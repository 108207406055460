import {connect} from "react-redux";
import {Admin, DispatchProps, InnerProps, StateProps} from "../components/Admin";
import {RootState, ThunkDispatch} from "../types";
import {doGetProducts} from "../actions/thunks/products";
import {setAlert} from "@kopjra/uikit";
import {I18n} from "react-redux-i18n";
import {doCreateSubscribeUser} from "../actions/thunks/customer";

function mapStateToProps({product}: RootState): StateProps {
    return {
        products: product.products,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetProducts: () => {
            dispatch(doGetProducts()).catch((error) => {
                console.error(error);
                dispatch(setAlert(I18n.t("error.getProducts"), "error"));
            });
        },
        onCreateSubscribeUser: (params) => {
            return dispatch(doCreateSubscribeUser(params));
        }
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Admin);