import en from "./en";
import it from "./it";
import {initLocale} from "@kopjra/uikit";

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number ?
    P extends string | number ?
        `${K}${"" extends P ? "" : "."}${P}`
        : never : never;

type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";

export type LocaleCommonType = typeof en;

export type LocaleCommonKeys = Leaves<LocaleCommonType>;

declare module 'react-redux-i18n' {
    interface I18n {
        t(code: LocaleCommonKeys, options?: any): string;
        l(timestamp: number, options?: any): string;
    }

    type TranslateProps = {
        className?: string;
        dangerousHTML?: boolean;
        style?: React.CSSProperties;
        tag?: React.ElementType;
        value: LocaleCommonKeys;
        [prop: string]: any;
    }
}

export const translations = {
    en,
    it,
};
export const defaultLocale = initLocale(translations, "it");
