import React, {useEffect, useState} from "react";
import {diffCart} from "../utils/commons";
import {CartType, SubscriptionError} from "../types/products";
import {Col, Row} from "react-bootstrap";
import {Customer} from "../types/customer";
import {Translate} from "react-redux-i18n";
import {KButton, KBUTTON_SIZE, KBUTTON_TYPE, KBUTTON_VARIANT, KCard, KSpace} from "@kopjra/uikit";
import {renderCart, renderSingleElementOutcome} from "./Cart";
import {BigWaiter} from "./BigWaiter";

export interface StateProps {
    cart: CartType;
    oldCart?: CartType;
    subscriptionErrors?: Map<string, SubscriptionError>;
    customer?: Customer;
}

export interface DispatchProps {
    onResetOldCart: () => void;
    onGetCustomer: () => Promise<void>;
    onGotoCart: () => void;
    onGotoHome: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const OrderConfirmation: React.FC<Props> = ({onGetCustomer, cart, oldCart, subscriptionErrors, customer, onResetOldCart, onGotoCart, onGotoHome}) => {
    const [lOldCart] = useState(oldCart);
    const [lSubscriptionErrors] = useState(subscriptionErrors);

    const diff = diffCart(lOldCart!, cart);
    const hasPaymentError = lSubscriptionErrors ? Array.from(lSubscriptionErrors.values()).some((value) => value === SubscriptionError.PAYMENT_ERROR) : false;
    useEffect(() => {
        if (!customer) {
            onGetCustomer().catch(console.log);
        }
    }, [customer, onGetCustomer]);
    useEffect(() => {
        if (oldCart || subscriptionErrors) {
            onResetOldCart();
        }
    });
    if (!lOldCart || !lSubscriptionErrors || !customer) {
        return (
            <BigWaiter/>
        );
    } else {
        return (
            <Col>
                {(!Object.keys(cart || {}).length) ? (
                    <>
                        <h1 className="text-center"><i className="fal fa-check-circle text-success"/>&nbsp;<Translate value="payment.confirmation.titleOK"/></h1>
                        <KSpace spaces={2}/>
                        <h4 className="text-center"><Translate value="payment.confirmation.subtitleOK" dangerousHTML={true} email={customer.email}/></h4>
                    </>
                ) : (!Object.keys(diff || {}).length) ? (
                    <>
                        <h1 className="text-center"><i className="fal fa-exclamation-circle text-danger"/>&nbsp;<Translate value="payment.confirmation.titleNone"/></h1>
                        <KSpace spaces={2}/>
                        <h4 className="text-center"><Translate value="payment.confirmation.subtitleNone"/></h4>
                        {hasPaymentError ? (
                            <h4 className="text-center"><Translate value="payment.confirmation.paymentError"/></h4>
                        ) : null}
                        <KSpace spaces={4}/>
                        <div className="text-center">
                            <KButton id={"goto-cart"} text={<Translate value="cart.title"/>}
                                     type={KBUTTON_TYPE.button} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.lg}
                                     onClick={() => onGotoCart()}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="text-center"><i className="fal fa-exclamation-triangle text-warning"/>&nbsp;<Translate value="payment.confirmation.titlePartial"/></h1>
                        <KSpace spaces={2}/>
                        <h4 className="text-center"><Translate value="payment.confirmation.subtitlePartial" dangerousHTML={true} email={customer.email}/></h4>
                    </>
                )}
                <KSpace spaces={2}/>
                {Object.keys(diff).length > 1 ? (
                    <Row className="cart">
                        <Col md={12} lg={8}>
                            {(cart.length) ? (
                                <KCard>
                                    {renderCart(cart, undefined, undefined, false)}
                                </KCard>
                            ) : null}
                            {(Object.keys(diff).length) ? (
                                <KCard>
                                    {renderCart(diff, undefined, undefined, true)}
                                </KCard>
                            ) : null}
                        </Col>
                    </Row>
                ) : Object.keys(diff).length === 1 ? renderSingleElementOutcome(Object.keys(diff)[0], diff[Object.keys(diff)[0]]) : null}
                <KSpace spaces={2}/>
                {!cart.length ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <h6><a className="link" href="/" onClick={() => onGotoHome()}><Translate value="cart.buy"/></a></h6>
                ) : null}
            </Col>
        );
    }
}