import {connect} from "react-redux";
import {Dispatch} from "redux";
import {App, DispatchProps, InnerProps, StateProps} from "../components/App";
import {RootState} from "../types";
import {RootAction} from "../actions";

function mapStateToProps({i18n}: RootState): StateProps {
    return {locale: i18n.locale};
}

function mapDispatchToProps(dispatch: Dispatch<RootAction>): DispatchProps {
    return {};
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(App);