import {connect} from "react-redux";
import {DispatchProps, InnerProps, SolutionDetail, StateProps} from "../components/SolutionDetail";
import {RootState, ThunkDispatch} from "../types";
import {doSelectSolution} from "../actions/thunks/products";
import {addToCart, emptyCart} from "../actions/products";
import {navigate, Routes} from "../utils/router";
import {doGetProductExclusive} from "../actions/thunks/customer";

function mapStateToProps({product, i18n}: RootState): StateProps {
    return {
        selected: product.selectedSolution,
        products: product.products,
        exclusives: product.exclusives,
        locale: i18n.locale,
        cart: product.cart,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onSelectSolution: (solution, group) => dispatch(doSelectSolution(solution, group)),
        onAddToCart: async (cartPiece) => { dispatch(emptyCart()); dispatch(addToCart(cartPiece)); navigate(Routes.CART); },
        onUpdateInCart: async (cartPiece) => { dispatch(addToCart(cartPiece)); navigate(Routes.CART); },
        onGetProductExclusive: () => dispatch(doGetProductExclusive()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(SolutionDetail);
