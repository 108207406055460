import {RootState, ThunkResult, UserProfileData} from "../../types";
import {
    canceledSubscription,
    gotBillingInfo,
    gotConsumptionStats,
    gotCustomer,
    gotInvoices,
    gotPaymentInfo,
    gotSubscriptions,
    savedBillingInfo,
    setSubscribing
} from "../customer";
import {
    addPaymentMethod,
    cancelSubscription,
    createSubscribeUser,
    deletePaymentMethod,
    downloadCustomerCSV,
    getBillingInfo,
    getConsumptionStats,
    getCustomer,
    getInvoice,
    getInvoices,
    getPaymentInfo,
    getSubscription,
    getSubscriptions,
    resendEmail,
    resetPassword,
    saveBillingInfo,
    setDefaultPaymentMethod,
    subscribeToPlan,
    updateSubscription,
    updateUserProfile
} from "../../api/customer";
import {
    BillingInfo,
    CreateSubscribeUserOutput,
    CreateSubscribeUserParams,
    Customer,
    PlanSubscriptionWithTotal
} from "../../types/customer";
import {
    createLinkToDownload,
    decomp,
    getProductById,
    hasValidDefaultPaymentMethod,
    PollingError,
    pollOnCondition,
    transformToConsumptionStatsParams,
    transformToInvoicesParams
} from "../../utils/commons";
import {
    CartType,
    DURATION,
    ExclusivePrices,
    FixedPrice,
    Price,
    priceTotal,
    Product,
    SubscriptionError
} from "../../types/products";
import {GetCursedParams, GetParams, SortingDirection} from "@kopjra/uikit";
import {Stripe} from "@stripe/stripe-js";
import BEStripe from "stripe";
import {
    addSubscriptionError,
    initSubscriptionErrors,
    removeFromCart,
    setExclusivePrices,
    setOldCart
} from "../products";
import {doGetProducts} from "./products";
import {getConsumptionPrices, getLevelPrices, getPackagePrices} from "../../api/dto/products";
import {ThunkDispatch} from "redux-thunk";
import {RootAction} from "../index";
import {toInvoice, toPlanSubscriptionWithTotal} from "../../api/dto/customer";

export const doGetCustomer = (): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotCustomer(await getCustomer()));
};

export const doGetBillingInfo = (): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotBillingInfo(await getBillingInfo()));
}

export const doSaveBillingInfo = (id: number, bi: BillingInfo): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(savedBillingInfo(await saveBillingInfo(id, bi)));
};

export const doGetPaymentInfo = (): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotPaymentInfo(await getPaymentInfo()));
}

interface CreateSubscriptionParams {
    type: "create";
    priceIds: string[];
    fixedPriceIds: string[];
    isOneTime: boolean;
    promoCode?: string;
    cartKeys: Set<string>;
}

interface UpdateSubscriptionParams {
    type: "update";
    newPriceIds: string[];
    newFixedPriceIds: string[];
    promoCode?: string;
    subscriptionId: string;
    cartKeys: Set<string>;
}

function addToParams(params: CreateSubscriptionParams, price: Price | FixedPrice, key: string) {
    params.cartKeys.add(key);
    if (price.recurring) {
        if (Array.isArray(price.id)) {
            params.priceIds = [...params.priceIds, ...price.id];
        } else {
            params.priceIds.push(price.id);
        }
        if ((price as Price).linked && (price as Price).description !== "freeTrial") {
            params.priceIds = [...params.priceIds, ...(price as Price).linked!.map((p) => p.id as string)]
        }
    } else {
        if (Array.isArray(price.id)) {
            params.fixedPriceIds = [...params.fixedPriceIds, ...price.id];
        } else {
            params.fixedPriceIds.push(price.id);
        }
    }

}

function hasSomething(params: CreateSubscriptionParams | UpdateSubscriptionParams): boolean {
    if (params.type === "update") {
        return params.newPriceIds.length > 0;
    } else {
        return (params.priceIds.length > 0) || (params.fixedPriceIds.length > 0);
    }
}

function addToUpdateParams(updateParams: Map<string, UpdateSubscriptionParams>, price: Price, subscriptionId: string, key: string, promotion?: BEStripe.PromotionCode) {
    if (!updateParams.has(subscriptionId)) {
        updateParams.set(subscriptionId, {
            promoCode: promotion?.id,
            newPriceIds: [],
            newFixedPriceIds: [],
            subscriptionId,
            type: "update",
            cartKeys: new Set(),
        })
    }

    updateParams.get(subscriptionId)!.cartKeys.add(key);
    if (price.recurring) {
        if (Array.isArray(price.id)) {
            updateParams.get(subscriptionId)!.newPriceIds = [...updateParams.get(subscriptionId)!.newPriceIds, ...price.id];
        } else {
            updateParams.get(subscriptionId)!.newPriceIds.push(price.id);
        }
        if (price.linked) {
            updateParams.get(subscriptionId)!.newPriceIds = [...updateParams.get(subscriptionId)!.newPriceIds, ...price.linked!.map((p) => p.id as string)]
        }
    } else {
        if (Array.isArray(price.id)) {
            updateParams.get(subscriptionId)!.newFixedPriceIds = [...updateParams.get(subscriptionId)!.newFixedPriceIds, ...price.id];
        } else {
            updateParams.get(subscriptionId)!.newFixedPriceIds.push(price.id);
        }
    }
}

export const doSubscribeToPlans = (customer: Customer, cart: CartType, cartUUID: string, stripe: Stripe | null, promotion?: BEStripe.PromotionCode): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    try {
        dispatch(setSubscribing(true));
        let exclusives = getState().product.exclusives;

        if (!exclusives) {
            await dispatch(doGetProductExclusive());
            exclusives = getState().product.exclusives as ExclusivePrices;
        }

        const oneTimeParams: CreateSubscriptionParams = {
            priceIds: [],
            fixedPriceIds: [],
            isOneTime: true,
            cartKeys: new Set(),
            promoCode: promotion?.id,
            type: "create",
        };
        const renewableParams: CreateSubscriptionParams = {
            priceIds: [],
            fixedPriceIds: [],
            isOneTime: false,
            cartKeys: new Set(),
            promoCode: promotion?.id,
            type: "create",
        };
        const updateParams: Map<string, UpdateSubscriptionParams> = new Map();

        for (const key of Object.keys(cart)) {
            const prices = cart[key];
            const decomped = decomp(key);
            for (const price of prices) {
                if (exclusives[decomped.id] /* && Object.values(FIXED_PRICE_TYPE).indexOf(price.type as any) === -1 */) {
                    addToUpdateParams(updateParams, price as Price, exclusives[decomped.id].subscriptionId, key, promotion);
                } else {
                    if (price.oneTime) {
                        addToParams(oneTimeParams, price, key);
                    } else {
                        addToParams(renewableParams, price, key);
                    }
                }
            }
        }

        const params = [oneTimeParams, renewableParams, ...Array.from(updateParams.values())];

        dispatch(initSubscriptionErrors());

        for (const param of params) {
            if (hasSomething(param)) {
                try {
                    let subscription = param.type === "create" ?
                        await subscribeToPlan(param.isOneTime, param.priceIds, param.fixedPriceIds, param.promoCode) :
                        await updateSubscription(param.subscriptionId, param.newPriceIds, param.newFixedPriceIds, param.promoCode)
                    ;
                    if (typeof subscription === "string") {
                        dispatch(addSubscriptionError(`${Array.from(param.cartKeys).join("$$")}`, subscription));
                    } else {
                        if (subscription.status === "active") {
                            dispatch(removeFromCart(Array.from(param.cartKeys)));
                        } else {
                            const clientSecret = ((subscription.latest_invoice as BEStripe.Invoice).payment_intent as BEStripe.PaymentIntent).client_secret;
                            if (stripe) {
                                try {
                                    // TODO: Manage the caso of SEPA debit payment
                                    await stripe.confirmCardPayment(clientSecret || "");
                                } catch (stripeError) {
                                    console.log(stripeError);
                                    dispatch(addSubscriptionError(`${Array.from(param.cartKeys).join("$$")}`, SubscriptionError.PAYMENT_ERROR));
                                }
                            }

                            try {
                                await pollOnCondition(() => getSubscription((subscription as BEStripe.Subscription).id), (element) => element && element.status === "active");
                                dispatch(removeFromCart(Array.from(param.cartKeys)));
                            } catch (error) {
                                if (error instanceof PollingError) {
                                    const errorSubscription = error.context as BEStripe.Subscription;

                                    if (errorSubscription.status === "incomplete") {
                                        dispatch(addSubscriptionError(`${Array.from(param.cartKeys).join("$$")}`, SubscriptionError.PROCESSING_PAYMENT));
                                    } else {
                                        dispatch(addSubscriptionError(`${Array.from(param.cartKeys).join("$$")}`, SubscriptionError.PAYMENT_ERROR));
                                    }
                                }
                            }
                        }
                    }
                } catch (e) {
                    dispatch(addSubscriptionError(`${Array.from(param.cartKeys).join("$$")}`, SubscriptionError.RESPONSE_ERROR));
                }
            }
        }

        dispatch(setOldCart(cart));
        dispatch(gotCustomer(undefined));
        dispatch(setExclusivePrices(undefined));
        dispatch(gotInvoices(undefined));
    } finally {
        dispatch(setSubscribing(false));
    }
};

export const doAddPaymentMethod = (pi: string): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotPaymentInfo(await addPaymentMethod(pi)));
};

export const doDeletePaymentMethod = (pi: string): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotPaymentInfo(await deletePaymentMethod(pi)));
};

export const doSetDefaultPaymentMethod = (pi: string): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotPaymentInfo(await setDefaultPaymentMethod(pi)));
};

export const doCheckCustomer = (): ThunkResult<Promise<boolean>> => async (dispatch, getState) => {
    return hasValidDefaultPaymentMethod(getState().customer.paymentInfo);
};

export const doResendEmail = (): ThunkResult<Promise<void>> => async () => {
    await resendEmail();
}

export const doResetPassword = (): ThunkResult<Promise<void>> => async () => {
    await resetPassword();
}

export const doUpdateUserProfile = (up: UserProfileData): ThunkResult<Promise<void>> => async () => {
    await updateUserProfile(up);
}

export const doGetInvoices = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const products = await getRetrieveProducts(dispatch, getState);
    let rawInvoices = await getInvoices(transformToInvoicesParams(query));
    dispatch(gotInvoices({items: rawInvoices.items.map((item) => toInvoice(item, products)), total: rawInvoices.total}));
}

export const doDownloadInvoice = (id: number): ThunkResult<Promise<void>> => async (dispatch) => {
    const invoice = await getInvoice(id);
    createLinkToDownload(invoice.pdfLink);
}

export const doGetConsumptionStats = (query: GetCursedParams): ThunkResult<Promise<{ next?: string, prev?: string }>> => async (dispatch) => {
    const stats = await getConsumptionStats(transformToConsumptionStatsParams(query));
    const next = (stats.length < (query.top || 5)) ? undefined : stats[stats.length - 1]?.customerId;
    dispatch(gotConsumptionStats(stats, next));
    return {next: next, prev: stats[0]?.customerId};
}

export const doDownloadCustomerCSV = (): ThunkResult<Promise<void>> => async (dispatch) => {
    const csv = await downloadCustomerCSV();
    const now = new Date();
    createLinkToDownload(`data:text/csv;charset=utf-8,${csv}`, `customers-export-${now.getFullYear()}${("0" + (now.getMonth() + 1)).slice(-2)}${("0" + now.getDate()).slice(-2)}.csv`);
}

export const doCancelSubscription = (subId: string): ThunkResult<Promise<void>> => async(dispatch) => {
    dispatch(canceledSubscription(await cancelSubscription(subId)));
}

export const doCreateSubscribeUser = (params: CreateSubscribeUserParams): ThunkResult<Promise<CreateSubscribeUserOutput>> => async () => {
    return await createSubscribeUser(params);
}

async function getRetrieveCustomer(dispatch: ThunkDispatch<RootState, undefined, RootAction>, getState: () => RootState): Promise<Customer> {
    let {customer} = getState().customer;
    if (!customer) {
        await dispatch(doGetCustomer());
        customer = getState().customer.customer as Customer;
    }
    return customer;
}

async function getRetrieveProducts(dispatch: ThunkDispatch<RootState, undefined, RootAction>, getState: () => RootState): Promise<Product[]> {
    let {products} = getState().product;

    if (!products) {
        await dispatch(doGetProducts(true));
        products = getState().product.products as Product[];
    }

    return products;
}

export const doGetProductExclusive = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const products = await getRetrieveProducts(dispatch, getState);
    const rawSubscriptions = await getSubscriptions();

    const exclusivePrices: ExclusivePrices = {};
    for (const sub of rawSubscriptions) {
        if (sub.status === "active") {
            const rawPrices = sub.items.data.map(value => value.price).filter(value => !!value) as BEStripe.Price[];
            const rawNonAddonPrices = rawPrices.filter(raw => !raw.metadata.addon && !!raw.recurring);
            const prices: Price[] = [...getLevelPrices(rawNonAddonPrices, true), ...getPackagePrices(rawNonAddonPrices, true), ...getConsumptionPrices(rawNonAddonPrices, true)];

            for (const price of prices) {
                const productById = getProductById(price.sproductId, products);
                if (productById?.exclusive) {
                    exclusivePrices[productById.id] = {price, subscriptionId: sub.id, modifiable: (priceTotal(price) === 0 && price.description === "freeTrial") || price.duration !== DURATION.year};
                }
            }
        }
    }

    dispatch(setExclusivePrices(exclusivePrices));
 }

export const doGetSubscriptions = (query: GetParams): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const customer = await getRetrieveCustomer(dispatch, getState);
    const products = await getRetrieveProducts(dispatch, getState);

    const rawSubscriptions = await getSubscriptions();
    let subscriptions: PlanSubscriptionWithTotal[] = [];
    for (const sub of rawSubscriptions) {
        subscriptions.push(toPlanSubscriptionWithTotal(sub, products, customer));
    }
    if (query.filter) {
        for (const filter of query.filter) {
            subscriptions = subscriptions.filter(value => value[filter.name as keyof PlanSubscriptionWithTotal] === filter.value);
        }
    }
    if (query.sort) {
        const direction = query.direction === SortingDirection.DOWN ? -1 : 1;
        const prop: keyof PlanSubscriptionWithTotal = query.sort as keyof PlanSubscriptionWithTotal;
        subscriptions.sort(((a, b) =>  (a[prop] || 0) < (b[prop] || 0) ? direction * -1 : direction * 1));
    }
    const total = subscriptions.length;
    subscriptions = subscriptions.slice(query.skip || 0, (query.skip || 0) + (query.top || 5));
    dispatch(gotSubscriptions(subscriptions, total));
}
