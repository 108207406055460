import {
    ConsumptionPrice,
    CURRENCY,
    DURATION,
    FIXED_PRICE_TYPE,
    FixedPrice,
    LevelPrice,
    PackagePrice,
    Price,
    PRICE_TYPE,
    Product,
    ProductIdType
} from "../../types/products";
import {IMAGES} from "@kopjra/uikit";
import Stripe from "stripe";
import {SolutionKey, SOLUTIONS} from "../../types/solutions";

export function toProduct(raw: Stripe.Product & {prices: Stripe.Price[]}): Product | undefined {
    const id = (raw.metadata?.internalId || raw.name) as ProductIdType;

    if (!raw.metadata.solution || !Object.keys(SOLUTIONS).includes(raw.metadata.solution)) {
        return;
    }

    let oneTime = !!JSON.parse((raw.metadata["oneTime"] ?? "false").toLowerCase());
    const rawNonAddonPrices = raw.prices.filter(raw => !raw.metadata.addon && !!raw.recurring);
    const levelPrices = getLevelPrices(rawNonAddonPrices, oneTime);
    const packagePrices = getPackagePrices(rawNonAddonPrices, oneTime);
    const consumptionPrices = getConsumptionPrices(rawNonAddonPrices, oneTime);

    return {
        id,
        sid: raw.id,
        icon: IMAGES[id] ? IMAGES[id].still.large.default : IMAGES["kopjra"].still.small.default,
        solution: raw.metadata.solution as SolutionKey,
        addons: getAddons(raw.prices, oneTime),
        prices: [...levelPrices, ...packagePrices, ...consumptionPrices],
        exclusive: raw.metadata.exclusivePricing === "true",
    }
}

export function toProductMap(products: Product[]): Map<string, Product> {
    return products.reduce<Map<string, Product>>((previousValue, currentValue) => previousValue.set(currentValue.sid, currentValue), new Map());
}

export function addLinkedToProduct(raw: Stripe.Product & {prices: Stripe.Price[]}, productMap: Map<string, Product>) {
    if (!raw.metadata.father || !productMap.has(raw.metadata.father)) {
        return;
    }

    const defaultPriceId = (raw as any).default_price;
    let defaultPrice: Price | undefined;

    const priceMap: Map<string, Price[]> = new Map();
    for (const rawPrice of raw.prices) {
        if (defaultPriceId === rawPrice.id) {
            defaultPrice = toPrice(rawPrice);
        }
        if (rawPrice.metadata["link"]) {
            if (!priceMap.has(rawPrice.metadata["link"])) {
                priceMap.set(rawPrice.metadata["link"], []);
            }

            priceMap.get(rawPrice.metadata["link"])!.push(toPrice(rawPrice));
        }
    }

    if (!defaultPrice) {
        defaultPrice = toPrice(raw.prices[0]);
    }

    for (const price of productMap.get(raw.metadata.father)!.prices) {
        const pricesToBeLinked: Price[] =
            Array.isArray(price.id) ?
                price.id.reduce<Price[]>((previousValue, currentValue) => [...previousValue, ...(priceMap.get(currentValue) || [])], []) :
                (priceMap.get(price.id) || []);
        price.linked = pricesToBeLinked.length > 0 ? pricesToBeLinked : [defaultPrice];
    }
}

export function setParentOf(rawPrices: Stripe.Price[]): Map<string, Stripe.Price> {
    const rawMap = rawPrices.reduce<Map<string, Stripe.Price>>((previousValue, currentValue) => previousValue.set(currentValue.id, currentValue), new Map());
    for (const raw of rawPrices) {
        if (raw.metadata.isExcessOf) {
            const parent = rawMap.get(raw.metadata.isExcessOf);
            if (parent) {
                parent.metadata.parentOf = raw.id;
            }
        }
    }
    return rawMap;
}

export function toPrice(rawPrice: Stripe.Price): Price {
    return {
        price: (+(rawPrice.unit_amount_decimal || 0)) / 100,
        id: rawPrice.id,
        oneTime: false,
        currency: rawPrice.currency as CURRENCY,
        recurring: !!rawPrice.recurring,
        type: PRICE_TYPE.consumption,
        duration: (rawPrice.recurring?.interval || "year") as DURATION,
        sproductId: (rawPrice.product as Stripe.Product).id || rawPrice.product as string,
        multiplier: +rawPrice.metadata.multiplier || 1,
        admin: rawPrice.metadata.admin === "true",
    }
}

export function getLevelPrices(rawPrices: Stripe.Price[], oneTime: boolean): LevelPrice[] {
    const levelPrices: LevelPrice[] = [];
    const rawMap = setParentOf(rawPrices);
    for (const raw of rawPrices) {
        if (raw.metadata.isExcessOf) {
            const parent = rawMap.get(raw.metadata.isExcessOf);
            if (parent) {
                levelPrices.push({
                    price: (+(raw.unit_amount_decimal || 0)) / 100,
                    recurring: !!raw.recurring || !!parent.recurring,
                    oneTime,
                    id: [parent.id, raw.id],
                    type: PRICE_TYPE.level,
                    fixed: (+(parent.unit_amount_decimal || 0)) / 100,
                    from: parent.transform_quantity?.divide_by || 1,
                    duration: (raw.recurring?.interval || "year") as DURATION,
                    currency: raw.currency as CURRENCY,
                    sproductId: (raw.product as Stripe.Product).id || raw.product as string,
                    description: raw.nickname || undefined,
                    multiplier: +raw.metadata.multiplier || 1,
                    fixedMultiplier: +parent.metadata.multiplier || 1,
                    admin: raw.metadata.admin === "true",
                });
            }
        }
    }
    return levelPrices.sort((a, b) => a.from < b.from ? -1 : 1);
}

export function getConsumptionPrices(rawPrices: Stripe.Price[], oneTime: boolean): ConsumptionPrice[] {
    const consumptionPrices: ConsumptionPrice[] = [];
    setParentOf(rawPrices);
    for (const raw of rawPrices) {
        if (raw.recurring?.usage_type === "metered" && !raw.metadata.isExcessOf && !raw.metadata.parentOf) {
            consumptionPrices.push({
                type: PRICE_TYPE.consumption,
                id: raw.id,
                oneTime,
                recurring: !!raw.recurring,
                price: (+(raw.unit_amount_decimal || 0)) / 100,
                duration: (raw.recurring?.interval || "year") as DURATION,
                currency: raw.currency as CURRENCY,
                sproductId: (raw.product as Stripe.Product).id || raw.product as string,
                description: raw.nickname || undefined,
                multiplier: +raw.metadata.multiplier || 1,
                admin: raw.metadata.admin === "true",
            });
        }
    }
    return consumptionPrices;
}

export function getPackagePrices(rawPrices: Stripe.Price[], oneTime: boolean): PackagePrice[] {
    const packagePrices: PackagePrice[] = [];
    setParentOf(rawPrices);
    for (const raw of rawPrices) {
        if (raw.billing_scheme === "per_unit" && !raw.metadata.isExcessOf && !raw.metadata.parentOf && raw.recurring?.usage_type !== "metered") {
            packagePrices.push({
                id: raw.id,
                oneTime,
                recurring: !!raw.recurring,
                type: PRICE_TYPE.package,
                to: raw.transform_quantity?.divide_by || 1,
                price: (+(raw.unit_amount_decimal || 0)) / 100,
                duration: (raw.recurring?.interval || "year") as DURATION,
                currency: raw.currency as CURRENCY,
                sproductId: (raw.product as Stripe.Product).id || raw.product as string,
                description: raw.nickname || undefined,
                multiplier: +raw.metadata.multiplier || 1,
                admin: raw.metadata.admin === "true",
            });
        }
    }
    return packagePrices.sort((a, b) => a.to < b.to ? -1 : 1);
}

export function getAddons(rawPrices: Stripe.Price[], oneTime: boolean): FixedPrice[] {
    return rawPrices.filter(raw => !!raw.metadata.addon).map<FixedPrice>(raw => ({
        id: raw.id,
        oneTime,
        recurring: !!raw.recurring,
        name: raw.metadata.addon as string,
        price: (+(raw.unit_amount_decimal || 0)) / 100,
        type: raw.recurring ? FIXED_PRICE_TYPE.recurrent : FIXED_PRICE_TYPE.unaTantum,
        optional: !raw.metadata.optional ? false : !!JSON.parse(raw.metadata.optional.toLowerCase()),
        duration: (raw.recurring?.interval) as DURATION | undefined,
        currency: raw.currency as CURRENCY,
        multiplier: +raw.metadata.multiplier || 1,
        inTrial: !raw.metadata.inTrial ? false : !!JSON.parse(raw.metadata.inTrial.toLowerCase()),
    }));
}
