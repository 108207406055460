import {ThunkResult} from "../../types";
import {getProducts, getPromotionCode, getTaxRate} from "../../api/products";
import {addToCart, cartError, emptyCart, gotProducts, selectSolution, setCartLoading} from "../products";
import {SolutionKey} from "../../types/solutions";
import {createId, getProductAndPrice, modifyCart, validatePromoCode} from "../../utils/commons";
import {gotPromotionCode} from "../customer";
import {CartType, Product} from "../../types/products";

export const doGetProducts = (all: boolean = false): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(gotProducts(await getProducts(all), await getTaxRate()));
};

export const doSelectSolution = (solutionKey: SolutionKey, group?: string): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const {product} = getState();
    if (!product.products) {
        dispatch(gotProducts(await getProducts(false), await getTaxRate()));
    }
    dispatch(selectSolution(solutionKey));
};

export const doGetPromotionCode = (code: string): ThunkResult<Promise<boolean>> => async (dispatch, getState) => {
    let promoCode = await getPromotionCode(code);
    const cart = getState().product.cart;
    if (promoCode && !validatePromoCode(promoCode, cart)) {
        promoCode = undefined;
    }
    dispatch(gotPromotionCode(promoCode));
    return !!promoCode;
}

export const doInitCartFromQuery = (priceIds: string[], code?: string): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    dispatch(setCartLoading(true));

    try {
        let products = getState().product.products;

        if (!products) {
            await dispatch(doGetProducts(true));
            products = getState().product.products as Product[];
        }

        if (products) {
            let cart: CartType = {};
            for (const priceId of priceIds) {
                const {product, price} = getProductAndPrice(products, priceId);
                if (!product || !price) {
                    throw new Error("Product or price not found");
                }
                const id = createId(product);
                const cartPiece = modifyCart(cart, id, [{price, op: "add", type: price.type}]);
                cart = {...cart, ...cartPiece};
            }

            dispatch(emptyCart());
            dispatch(addToCart(cart));

            dispatch(gotPromotionCode(undefined));
            if (code) {
                await dispatch(doGetPromotionCode(code));
            }
        }
    } catch (e: any) {
        console.log(e.message);
        dispatch(cartError());
    } finally {
        dispatch(setCartLoading(false));
    }

}
