import React, {useEffect, useState} from "react";
import {computeCartTotal, computeTotal, decomp, isFixedPrice, isPrice} from "../utils/commons";
import {
    IMAGES,
    KAccordion,
    KButton,
    KBUTTON_SIZE,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KCheck,
    KContainer,
    KErrorPage,
    KForm,
    KInput,
    KSpace,
    KSpinner,
    KSpinnerSize
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {I18n, Localize, Translate} from "react-redux-i18n";
import {SolutionKey} from "../types/solutions";
import {
    AnyPrice,
    CartType,
    ConsumptionPrice,
    ExclusivePrices,
    LevelPrice,
    PackagePrice,
    Price,
    PRICE_TYPE,
    priceTotal,
    ProductIdType
} from "../types/products";
import Stripe from "stripe";
import {useQuery} from "../utils/router";
import {SecureRow} from "./SecureRow";
import {useAuth0} from "@auth0/auth0-react";

export interface StateProps {
    cart: CartType;
    promotion?: Stripe.PromotionCode;
    tax: number;
    loading: boolean;
    cartError: boolean;
    exclusives?: ExclusivePrices;
}

export interface DispatchProps {
    onGoHome: () => void;
    onRemoveFromCart: (id: string) => void;
    onEditCartElement: (solution: SolutionKey, id: string) => void;
    onProceed: () => void;
    onGetPromotion: (code: string) => Promise<boolean>;
    onRemovePromotion: () => void;
    onCreateCartFromQuery: (priceId: string[], code?: string) => Promise<void>;
    onGetProductExclusive: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

interface CartRowProps {
    withDivider?: boolean;
    noMarginTop?: boolean;
    noMarginBottom?: boolean;
    className?: string;
}

const CartRow: React.FC<CartRowProps> = ({className, noMarginBottom, children, withDivider= false, noMarginTop = false}) => {
    return (
        <div className={`cart-row${withDivider ? " bottom-divider" : ""}${noMarginTop ? " no-margin-top" : ""}${noMarginBottom ? " no-margin-bottom" : ""} ${className}`}>
            {children}
        </div>
    );
}

interface CartRowLineProps {
    left: JSX.Element;
    center?: JSX.Element;
    right?: JSX.Element;
}

const CartRowLine: React.FC<CartRowLineProps> = ({left, center, right }) => {
    return (
        <div className="line">
            <div className="left">
                {left}
            </div>
            {center ? (
                <div className="center">
                    {center}
                </div>
            ) : null}
            {right ? (
                <div className="pull-right">
                    {right}
                </div>
            ) : null}
        </div>
    );
}

export function renderSingleElementOutcome(id: string, cartElement: AnyPrice[]) {
    const decomped = decomp(id);
    const solutionKey = decomped.solution;
    const productId = decomped.id;
    return <div className="single-prod">
        <Row>
            <Col className="text-center solution">
                <img src={IMAGES[productId]?.still.large.default || IMAGES["kopjra"]?.still.small.default} alt={`${productId}_icon`} width={105}/>
                <KSpace/>
                <div className="caption"><Translate value={`${productId}.title`}/></div>
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                {renderCartElement(cartElement, productId)}
            </Col>
        </Row>
        <KSpace spaces={2}/>
        <Row>
            <Col lg={2}>
                <KButton id={"cart-user-login"} text={<><i className="fal fa-sign-in"/> <Translate value="user.login"/></>} variant={KBUTTON_VARIANT.primary} fill={true} size={KBUTTON_SIZE.nm} onClick={() => { window.open(I18n.t(`${solutionKey}.url`, "_blank")); }}/>
            </Col>
        </Row>
    </div>
}

export function renderPrice(price: Price | undefined, productId: ProductIdType, modifiable?: boolean): JSX.Element {
    if (price) {
        let count = "";
        let init = ""
        switch (price.type) {
            case PRICE_TYPE.level:
                count = I18n.l((price as LevelPrice).from);
                break;
            case PRICE_TYPE.package:
                count = I18n.l((price as PackagePrice).to);
                break;
            case PRICE_TYPE.consumption:
                init = I18n.l((price as ConsumptionPrice).price);
                break;
        }
        return <Translate className={modifiable === undefined ? "" : modifiable ? "upgradable" : "non-upgradable"} value={`${productId}.${price.type}.shorts.item` as any} price={init} count={count} duration={I18n.t(`generic.duration.${price.duration}` as any)}/>
    }
    return <></>;
}

export function getPriceTranslation(price: Price | undefined, productId: ProductIdType, exclusives?: ExclusivePrices) {
    let priceTranslation: JSX.Element | undefined;
    if (price) {
        let modifiable = true;
        if (exclusives && exclusives[productId]) {
            modifiable = modifiable && exclusives[productId].modifiable;
            if (priceTotal(price) === 0 && exclusives[productId].price.id === price.id) {
                modifiable = false;
            }
        }
        priceTranslation = <>
            {renderPrice(exclusives && exclusives[productId]?.price, productId, modifiable)}
            {renderPrice(price, productId)}
        </>;
    }
    return priceTranslation;
}

function renderCartElement(cartElement: AnyPrice[], productId: ProductIdType, exclusives?: ExclusivePrices) {
    const prices = cartElement;
    const notFixed = prices.find(isPrice);
    const priceTranslation = getPriceTranslation(notFixed, productId, exclusives);
    const fixed = prices.filter(isFixedPrice);
    return (
        <div className="product">
            {/*<div className="pimage"><img src={IMAGES[productKey].still.large.dark} alt={`${productKey}_icon`} width={50}/></div>*/}
            <div className="title"><Translate value={`${productId}.subtitle`}/></div>
            <div className="details">
                <div className="price">{priceTranslation}</div>
                {fixed.map((f, index) => (
                    <div className="price" key={index}>+ <Translate value={`${productId}.addons.shorts.${f.name}` as any}/></div>
                ))}
            </div>
        </div>
    );
}

export function renderCart (cart: CartType, onEditCartElement?: (solution: SolutionKey, id: string) => void, onRemoveFromCart?: (id: string) => void, confirmed?: boolean, exclusives?: ExclusivePrices) {
    return Object.keys(cart).map((id, index) => {
        const decomped = decomp(id);
        const productId = decomped.id;
        const solutionKey = decomped.solution;

        return <CartRow withDivider={Object.keys(cart).length - 1 !== index} key={index}>
            <CartRowLine
                left={<div className="solution">
                    <img src={IMAGES[productId]?.still.large.default || IMAGES["kopjra"]?.still.small.default} alt={`${productId}_icon`} width={70}/>
                    <KSpace/>
                    <div className="caption"><Translate value={`${productId}.title`}/></div>
                </div>}
                center={(
                    <>
                        {renderCartElement(cart[id], productId, exclusives)}
                    </>
                )}
                right={(
                    <div className="options">
                        {confirmed === undefined ? (
                            <Localize
                                className="bold"
                                value={computeTotal(cart[id])[0]}
                                options={{style: "currency", currency: "EUR"}}
                            />
                        ) : (confirmed) ? <Translate className="text-success bold" value="payment.confirmation.confirmed"/> :
                            <Translate className="text-danger bold" value="payment.confirmation.notConfirmed"/>}
                        {onEditCartElement ? (
                            <>
                                <div/>
                                <KButton id={"cart-element-edit"} text={<><i className="fal fa-edit"/> <Translate value="cart.edit"/></>} variant={KBUTTON_VARIANT.stript}
                                         size={KBUTTON_SIZE.xs} onClick={() => onEditCartElement(solutionKey, id)}
                                />
                            </>
                        ) : null}
                        {onRemoveFromCart ? (
                            <>
                                <div/>
                                <KButton id={"cart-element-delete"} text={<><i className="fal fa-trash"/> <Translate value="cart.delete"/></>} variant={KBUTTON_VARIANT.stript}
                                         size={KBUTTON_SIZE.xs} onClick={() => onRemoveFromCart(id)}
                                />
                            </>
                        ) : null}
                    </div>
                )}
            />
        </CartRow>
    });
}

export function renderPromotionCode(promo: Stripe.PromotionCode, onClick: () => void): JSX.Element {
    if (promo.coupon.percent_off) {
        return <div className="text-start text-success">
            <Translate value="cart.couponPercent" count={promo.coupon.percent_off}/>
            <i className="pull-right fal fa-trash text-secondary" style={{cursor: "pointer", position: "relative", top: 4}} onClick={() => onClick()}/>
        </div>
    } else if (promo.coupon.amount_off) {
        return <div className="text-start text-success">
            <Translate value="cart.couponAmount" count={promo.coupon.amount_off / 100}/>
            <i className="pull-right fal fa-trash text-secondary" style={{cursor: "pointer", position: "relative", top: 4}} onClick={() => onClick()}/>
        </div>
    } else {
        return <span/>
    }
}

export const Cart: React.FC<Props> = ({onGetProductExclusive, exclusives, onCreateCartFromQuery, cartError, loading, tax, onRemovePromotion, onGetPromotion, promotion, cart, onGoHome, onEditCartElement, onRemoveFromCart, onProceed}) => {
    const [cartTotal] = computeCartTotal(cart);
    const [discountedTotal] =  computeCartTotal(cart, promotion);
    const discount = cartTotal - discountedTotal;
    const [virginCode, setVirginCode] = useState<string>();
    const [verifyingCode, setVerifyingCode] = useState(false);
    const [error, setError] = useState(false);
    const queryPriceId = useQuery().get("prices");
    const queryCode = useQuery().get("codes");
    const fromUrl = queryPriceId || queryCode;
    const [acceptedTos, setAcceptedTos] = useState(false);
    const [acceptedTos2, setAcceptedTos2] = useState(false);
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (Object.keys(cart).length === 0 && !queryPriceId) {
            onGoHome();
        }
    }, [cart, onGoHome, queryPriceId]);
    useEffect(() => {
        if (queryPriceId) {
            onCreateCartFromQuery(queryPriceId.split(","), queryCode || undefined).catch(console.log);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGoHome, queryPriceId, queryCode, onCreateCartFromQuery]);

    useEffect(() => {
        if (!exclusives && isAuthenticated) {
            onGetProductExclusive().catch(console.log);
        }
    }, [exclusives, isAuthenticated, onGetProductExclusive]);

    return (
        <KContainer fluid="md" marginTop={75}>
            {loading ? (
                <h1><KSpinner size={KSpinnerSize.nm}/></h1>
            ) : cartError ? (
                <KErrorPage title={<Translate value="error.page"/>} code={"400"} message={<Translate value="error.malformedCart"/>}/>
            ) : (
                <Row className="cart">
                    <Col
                        md={12}
                        lg={8}
                    >
                        <KCard
                            header={<Translate value="cart.title"/>}
                        >
                            {renderCart(cart, !fromUrl ? onEditCartElement : undefined, !fromUrl ? onRemoveFromCart : undefined, undefined, exclusives)}
                        </KCard>
                    </Col>
                    <Col
                        md={12}
                        lg={4}
                    >
                        <KCard
                            header={<Translate value="productDetail.total"/>}
                        >
                            {/*{promotion ? (*/}
                            {/*    <CartRow withDivider={true} noMarginBottom={true}>*/}
                            {/*        {renderPromotionCode(promotion, () => onRemovePromotion())}*/}
                            {/*        <KSpace spaces={1}/>*/}
                            {/*    </CartRow>*/}
                            {/*) : null}*/}
                            <CartRow withDivider={true} noMarginBottom={true}>
                                <KAccordion elements={[{
                                    title: <Translate value="cart.couponTitle"/>,
                                    body: promotion ? renderPromotionCode(promotion, () => onRemovePromotion()) : <>
                                        <Row>
                                            <Col xs={7}>
                                                <KInput id={"coupon"} value={virginCode}
                                                        onChange={(e) => setVirginCode(e.target.value)}
                                                        placeholder={I18n.t("cart.couponPlaceholder")}
                                                        disabled={verifyingCode}
                                                />
                                            </Col>
                                            <Col xs={5}>
                                                <KButton
                                                    id={"cart-verify-coupon"}
                                                    disabled={verifyingCode}
                                                    text={verifyingCode ? <KSpinner/> : <Translate value="cart.couponButton"/>} size={KBUTTON_SIZE.xs} fill={true}
                                                    onClick={async () => {
                                                        setVerifyingCode(true);
                                                        setError(!(await onGetPromotion(virginCode || "")));
                                                        setVerifyingCode(false);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {error ? (
                                            <Row>
                                                <Col><Translate value="cart.couponError" className="text-danger"/></Col>
                                            </Row>
                                        ) : null}
                                    </>
                                }]} actives={(virginCode || promotion) ? [0] : []}/>
                            </CartRow>
                            <CartRow withDivider={true}>
                                <CartRowLine
                                    left={<Translate value="cart.subtotal" className="upper"/>}
                                    right={<>
                                        <Localize value={cartTotal} options={{style: "currency", currency: "EUR"}} style={{textDecoration: discount ? "line-through" : "none"}}/>
                                        {discount ? <Localize value={discountedTotal} options={{style: "currency", currency: "EUR"}} style={{marginLeft: 10}}/> : null}
                                    </>}
                                />
                                <CartRowLine
                                    left={<Translate value="cart.iva" count={tax * 100} className="upper"/>}
                                    right={<Localize value={discountedTotal * tax} options={{style: "currency", currency: "EUR"}}/>}
                                />
                            </CartRow>
                            <CartRow>
                                <CartRowLine
                                    left={<><Translate value="productDetail.total" className="upper bold"/></>}
                                    right={<Localize value={discountedTotal + discountedTotal * tax} options={{style: "currency", currency: "EUR"}}/>}
                                />
                            </CartRow>
                            <KSpace/>
                            <KForm onSubmit={() => onProceed()}>
                                <CartRow className="tos-check" noMarginBottom={true}>
                                    <KCheck id={"tos"} label={<Translate value={"cart.tos"} dangerousHTML={true}/>} required={true} checked={acceptedTos} onChange={() => setAcceptedTos(!acceptedTos)}/>
                                </CartRow>
                                <CartRow className="tos-check" noMarginBottom={true} noMarginTop={true}>
                                    <KCheck id={"tos2"} label={<Translate value={"cart.tos2"} dangerousHTML={true}/>} required={true} checked={acceptedTos2} onChange={() => setAcceptedTos2(!acceptedTos2)}/>
                                </CartRow>
                                <CartRow noMarginTop={true}>
                                    <KButton id={"startBuying"} text={<><i className="fal fa-angle-double-right" style={{fontSize: 24, position: "relative", top: 3}}/> <Translate value="cart.proceed"/></>} fill={true}
                                             type={KBUTTON_TYPE.submit} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.nm}
                                    />
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    {/*<h6><a className="link" href="#" onClick={() => onGoHome()}><Translate value="cart.buy"/></a></h6>*/}
                                </CartRow>
                            </KForm>
                        </KCard>
                    </Col>
                </Row>
            )}
            <SecureRow/>
        </KContainer>
    );
}
