import React, {useEffect, useState} from "react";
import {Customer} from "../types/customer";
import {I18n, Localize, Translate} from "react-redux-i18n";
import {computeCartTotal, computeTotal, decomp, isFixedPrice, isPrice, showAlert} from "../utils/commons";
import PaymentMethods from "../containers/PaymentMethods";
import {useStripe} from "@stripe/react-stripe-js";
import {Stripe} from "@stripe/stripe-js";
import BEStripe from "stripe";
import {CartType, ExclusivePrices, priceTotal} from "../types/products";
import {Alert, Col, Row} from "react-bootstrap";
import {IMAGES, KButton, KBUTTON_SIZE, KBUTTON_VARIANT, KSpinner, KSpinnerSize} from "@kopjra/uikit";
import {SolutionKey} from "../types/solutions";
import {getPriceTranslation} from "./Cart";

export interface StateProps {
    customer?: Customer;
    locale: string;
    cart: CartType;
    promotion?: BEStripe.PromotionCode;
    cartUUID: string;
    tax: number;
    exclusives?: ExclusivePrices;
    subscribing: boolean;
}

export interface DispatchProps {
    onSubscribeToPlans: (customer: Customer, cart: CartType, cartUUID: string, stripe: Stripe | null, promotion?: BEStripe.PromotionCode) => Promise<void>;
    onGetCustomer: () => Promise<void>;
    onCheckCustomer: () => Promise<boolean>;
    onGetProductExclusive: () => Promise<void>;
    onEditCartElement: (solution: SolutionKey, id: string) => void;
    onRemoveFromCart: (id: string) => void;
}

export interface InnerProps {
    onPaymentFinished: () => void;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const PaymentInfo: React.FC<Props> = ({subscribing, onGetCustomer, onRemoveFromCart, onEditCartElement, exclusives, onGetProductExclusive, tax, promotion, customer, cart, onCheckCustomer, onSubscribeToPlans, onPaymentFinished, cartUUID }) => {
    const [cartTotal] = computeCartTotal(cart);
    const [discountedTotal, hasMetered] =  computeCartTotal(cart, promotion);
    const discount = cartTotal - discountedTotal;
    const stripe = useStripe();
    const [payingZero, setPayingZero] = useState(false);

    useEffect(() => {
        if (!exclusives) {
            onGetProductExclusive().catch(console.log);
        }
    }, [exclusives, onGetProductExclusive]);

    useEffect(() => {
        if (!customer) {
            onGetCustomer().catch(console.log);
        }
    }, [customer, onGetCustomer])

    useEffect(() => {
        if (customer && discountedTotal === 0 && !subscribing && !hasMetered) {
            setPayingZero(true);
            (async () => {
                await onSubscribeToPlans(customer, cart, cartUUID, stripe, promotion);
                onPaymentFinished();
            })().catch(console.log);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountedTotal, customer, hasMetered]);

    let hasExclusive = false;
    let hasExclusiveNonModifiable = false;

    for (const key of Object.keys(cart)) {
        const decomped = decomp(key);
        if (exclusives && exclusives[decomped.id] && priceTotal(exclusives[decomped.id].price) > 0) {
            hasExclusive = true;

            if (!exclusives[decomped.id].modifiable) {
                hasExclusiveNonModifiable = true;
            }
        }
    }

    return !exclusives ? <h2><KSpinner size={KSpinnerSize.lg}/></h2> : (
        <>
            {hasExclusive ? (
                <Row className="toolbar-cart">
                    <div className="payment">
                        <div className={"sectionTitle"}><Translate value="payment.summary"/></div>
                        {Object.keys(cart).map((id, index) => {
                            const decomped = decomp(id);
                            const productId = decomped.id;
                            const solutionKey = decomped.solution;
                            const prices = cart[id];
                            const notFixed = prices.find(isPrice);
                            const fixed = prices.filter(isFixedPrice);
                            const priceTranslation = getPriceTranslation(notFixed, productId, exclusives);
                            const modifiable = !exclusives || !exclusives[productId] || exclusives[productId].modifiable;
                            return <div className="product" key={index}>
                                {exclusives && exclusives[productId] ? (
                                    <Alert variant={modifiable ? "warning" : "danger"}>
                                        <Translate value={modifiable ? `${productId}.exclusiveWarn` as any: `${productId}.exclusiveError` as any}/>
                                    </Alert>
                                ) : null}
                                <img src={IMAGES[productId].still.large.dark} alt={`${productId}_icon`} width={50}/>
                                <div className="central">
                                    <span className="title"><Translate className={!modifiable ? "text-danger" : ""} value={`${productId}.title`}/></span>
                                    <div>
                                    <span className="details">
                                        {priceTranslation}
                                    </span>
                                        {fixed.map((f, index) => (
                                            <span className="details" key={index}>+ <Translate value={`${productId}.addons.shorts.addons.${f.name}` as any}/></span>
                                        ))}
                                    </div>
                                </div>
                                <div className="pull-right text-right">
                                                <span className="title">
                                                    <Localize
                                                        value={computeTotal(prices)[0]}
                                                        options={{style: "currency", currency: "EUR"}}
                                                    />
                                                </span>
                                    {(!exclusives || !exclusives[productId] || exclusives[productId].modifiable) ? (
                                        <KButton id={"toolbar-edit"} text={<i className="fal fa-edit"/>} variant={KBUTTON_VARIANT.stript}
                                                 size={KBUTTON_SIZE.xs} onClick={() => onEditCartElement(solutionKey, id)}
                                        />
                                    ) : null }
                                    &nbsp;
                                    &nbsp;
                                    <KButton id={"toolbar-delete"} text={<i className="fal fa-trash"/>} variant={KBUTTON_VARIANT.stript}
                                             size={KBUTTON_SIZE.xs} onClick={() => onRemoveFromCart(id)}
                                    />
                                </div>
                            </div>
                        })}
                        <div className="product last">
                            <div className="pull-left titlish">
                                <Translate value="productDetail.total" className="bold"/> <Translate value="productDetail.iva"/>
                            </div>
                            <div className="pull-right titlish">
                                <Localize
                                    value={cartTotal + cartTotal * tax}
                                    options={{style: "currency", currency: "EUR"}}
                                    className="bold"
                                />
                            </div>
                        </div>
                    </div>
                </Row>
            ) : null}
            {payingZero ? (
                <Row>
                    <Col>
                        <h2><KSpinner size={KSpinnerSize.lg}/></h2>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col>
                        <PaymentMethods
                            buttonLabel={<>
                                <i className="fal fa-lock"/>
                                <Translate value="payment.pay" style={{marginLeft: 5}}/>
                                <Localize value={cartTotal + cartTotal * tax} options={{style: "currency", currency: "EUR"}} style={{marginLeft: 10, textDecoration: discount ? "line-through" : "none"}}/>
                                {discount ? <Localize value={discountedTotal + discountedTotal * tax} options={{style: "currency", currency: "EUR"}} style={{marginLeft: 10}}/> : null}
                            </>}
                            buttonDisabled={hasExclusiveNonModifiable}
                            onHandler={async () => {
                                if (!await onCheckCustomer()) {
                                    showAlert(I18n.t("payment.defaultError"));
                                    return;
                                }
                                await onSubscribeToPlans(customer!, cart, cartUUID, stripe, promotion);

                                onPaymentFinished();
                            }}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}
