import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, Toolbar} from "../components/Toolbar";
import {RootState, ThunkDispatch} from "../types";
import {confirmBox, saveLocale} from "@kopjra/uikit";
import {I18n, setLocale} from "react-redux-i18n";
import {navigate, Routes} from "../utils/router";
import {removeFromCart} from "../actions/products";
import {closeDropdownMenu} from "@kopjra/uikit/dist/src/lib/commons";

function mapStateToProps({i18n, product}: RootState): StateProps {
    return {
        locale: i18n.locale,
        cart: product.cart,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onChangeLocale: (locale) => {saveLocale(locale); dispatch(setLocale(locale))},
        onGoHome: () => {navigate(Routes.HOME)},
        onGoAccount: () => {navigate(Routes.USER_ACCOUNT)},
        onGoToCart: () => {navigate(Routes.CART); closeDropdownMenu();},
        onRemoveFromCart: async (id) => { await confirmBox({
            message: I18n.t("cart.deleteConfirm"),
            yesText: I18n.t("generic.yes"),
            noText:  I18n.t("generic.no"),
            yesFunc: () => { dispatch(removeFromCart(id)); },
        })},
        onGoBilling: () => {navigate(Routes.USER_BILLING)},
        onGoManagePayment: () => {navigate(Routes.USER_PAYMENT)},
        onGoSubscriptions: () => {navigate(Routes.USER_SUBSCRIPTIONS)},
        onGoInvoices: () => {navigate(Routes.USER_INVOICES)},
        onGoSuperUse: () => {navigate(Routes.SUPER_USE)},
        onGoSuperAdmin: () => {navigate(Routes.SUPER_ADMIN)},
        onEditCartElement: (solution, id) => {navigate(Routes.SOLUTION_DETAIL(solution, id)); closeDropdownMenu();},
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Toolbar);
