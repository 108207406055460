import React, {useEffect, useState} from "react";
import {
    confirmBox,
    KAccordion,
    KButton,
    KBUTTON_SIZE,
    KBUTTON_VARIANT,
    KCheck,
    KInput,
    KLabel,
    KSpace,
    KSpinner,
    KSpinnerSize
} from "@kopjra/uikit";
import {I18n, Translate} from "react-redux-i18n";
import {Col, Row} from "react-bootstrap";
import {CardElement, IbanElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {BillingInfo, Customer, PaymentInfo} from "../types/customer";
import {showAlert, twoDigits} from "../utils/commons";
import Stripe from "stripe";

export interface StateProps {
    customer?: Customer;
    billingInfo?: BillingInfo;
    paymentInfo?: PaymentInfo;
}

export interface DispatchProps {
    onGetPaymentInfo: () => Promise<void>;
    onAddPaymentMethod: (pi: string) => Promise<void>;
    onSetDefaultPaymentMethod: (pi: string) => Promise<void>;
    onDeletePaymentMethod: (pi: string) => Promise<void>;
}

export interface InnerProps {
    buttonLabel: JSX.Element;
    onHandler: () => Promise<void> | void;
    withSepa?: boolean;
    buttonDisabled?: boolean;
}

export type Props = StateProps & DispatchProps & InnerProps;

function getPaymentMethodElement(defaultId: string | undefined, pi: Stripe.PaymentMethod, onCheck: () => void, onDeletePaymentMethod: () => Promise<void> | void): JSX.Element {
    let icon = <i className="fal fa-money-bill cc"/>;
    let expiration: string | JSX.Element = "";
    let expired = false;
    switch (pi.type) {
        case "card":
            if (pi.card?.brand && pi.card?.brand !== "unknown") {
                icon = <i className={`fab fag fa-cc-${pi.card.brand} cc`}/>;
            } else {
                icon = <i className={`fab fag fa-cc-stripe cc`}/>;
            }
            if (pi.card?.exp_month && pi.card.exp_year) {
                const nowMonth = (new Date()).getMonth();
                const nowYear = (new Date()).getFullYear();
                if (nowYear >= pi.card.exp_year && nowMonth + 1 > pi.card.exp_month) {
                    expiration = <Translate value="payment.expired" month={twoDigits(pi.card.exp_month)} year={twoDigits(pi.card.exp_year)} className="text-danger" style={{marginLeft: 45}}/>;
                    expired = true;
                } else {
                    expiration = <span style={{marginLeft: 45}}>{twoDigits(pi.card.exp_month)}/{twoDigits(pi.card.exp_year)}</span>;
                }
            }
            break;
        case "sepa_debit":
            icon = <i className={`fal fag fa-university cc`}/>;
            break;
    }
    const element = pi.card || pi.sepa_debit;
    return <div>
        <div className="pull-left">
            <KCheck
                id={pi.id}
                label={<>{icon}{element?.last4 ? <span style={{marginLeft: 20}}>&nbsp;&nbsp;****&nbsp;{element?.last4}</span> : ""}{expiration}{pi.id === defaultId ? <><KSpace spaces={2.5} v={true}/><i className="fal fag fa-check-circle text-success cc"/></> : null}</>}
                disabled={expired}
                defaultChecked={pi.id === defaultId}
                inline={true}
                type={"radio"}
                name="saved_payment"
                onChange={() => onCheck()}
            />
        </div>
        <div className="pull-right" style={{display: "inline-block", paddingRight: 20, marginTop: 5}}>
            <KButton
                id={"payment-delete"}
                text={<><i className="fal fa-trash"/> <Translate value="payment.delete"/></>}
                variant={KBUTTON_VARIANT.stript}
                onClick={() => onDeletePaymentMethod()}
                size={KBUTTON_SIZE.xs}
            />
        </div>
    </div>
}

export const PaymentMethods: React.FC<Props> = ({buttonDisabled, billingInfo, paymentInfo, onGetPaymentInfo, onDeletePaymentMethod, withSepa = false, onHandler, onAddPaymentMethod, onSetDefaultPaymentMethod, buttonLabel, customer}) => {
    const [accSelected, setAccSelected] = useState<number[]>([0]);
    const [sepaName, setSepaName] = useState<string | undefined>(billingInfo?.ragioneSociale);
    const [sepaEmail, setSepaEmail] = useState<string | undefined>(billingInfo?.email);
    const [waiting, setWaiting] = useState(false);
    const [allWaiting, setAllWaiting] = useState(false);

    useEffect(() => {
        (async () => {
            if (customer && !paymentInfo) {
                setAllWaiting(true);
                await onGetPaymentInfo();
                setAllWaiting(false);
            }
        })().catch((err) => {
            console.log(err);
            setAllWaiting(false);
        })
    }, [customer, onGetPaymentInfo, paymentInfo])

    const [selectedId, setSelectedId] = useState<string | undefined>(paymentInfo?.defaultPaymentMethodId);

    const stripe = useStripe();
    const elements = useElements();

    const accordionElements = [];
    const stripeOptions = {
        style: {
            base: {
                fontSize: "14px",
                color: "#363d49",
                backgroundColor: "#f6f6f7",
                letterSpacing: "0.5px",
                fontFamily: "KTextFont, sans-serif",
                "::placeholder": {
                    color: "#9ca0a5"
                }
            },
            invalid: {
                color: "#ef615e"
            }
        }
    };
    let hasSaved = false;
    if (paymentInfo?.paymentMethods.length) {
        hasSaved = true;
        accordionElements.push({
            title: <Translate value="payment.saved"/>,
            body: (
                <Row>
                    <Col className="text-start">
                        {
                            paymentInfo?.paymentMethods.map((pi, index) => (
                                <Row key={index}>
                                    <Col>
                                        {getPaymentMethodElement(paymentInfo?.defaultPaymentMethodId, pi, () => {
                                            setSelectedId(pi.id);
                                        }, async () => {
                                            try {
                                                if (await confirmBox({message: I18n.t("payment.deleteConfirmation"), noText: I18n.t("generic.no"), yesText: I18n.t("generic.yes")})) {
                                                    setAllWaiting(true);
                                                    await onDeletePaymentMethod(pi.id);
                                                }
                                            } catch (err) {
                                                showAlert(I18n.t("payment.deleteError"), "error");
                                            } finally {
                                                setAllWaiting(false);
                                            }
                                        })}
                                    </Col>
                                </Row>
                            ))
                        }
                    </Col>
                </Row>
            )
        })
    }

    accordionElements.push({
        title: <Translate value="payment.newCard"/>,
        body: (
            <Row>
                <Col className="text-start">
                    <KLabel text={<Translate value="payment.cardData"/>}/>
                    <CardElement options={stripeOptions}/>
                </Col>
            </Row>
        ),
    });

    if (withSepa) {
        accordionElements.push({
            title: <Translate value="payment.sepa"/>,
            body: (
                <>
                    <Row>
                        <Col className="text-start">
                            <KInput id="sepaName" label={<Translate value="payment.sepaName"/>}
                                    value={sepaName}
                                    onChange={(event) => {
                                        setSepaName(event.target.value);
                                    }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-start">
                            <KInput id="sepaEmail" label={<Translate value="payment.sepaEmail"/>}
                                    value={sepaEmail}
                                    onChange={(event) => {
                                        setSepaEmail(event.target.value);
                                    }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-start">
                            <KLabel text={<Translate value="payment.iban"/>}/>
                            <IbanElement options={{supportedCountries: ["SEPA"], ...stripeOptions}}/>
                        </Col>
                    </Row>
                </>
            ),
        });
    }

    return (
        <div style={{paddingLeft: 40, paddingRight: 40}}>
            {allWaiting ? <h2><KSpinner size={KSpinnerSize.lg}/></h2> : (
                <>
                    <KAccordion exclusive={true} actives={accSelected} elements={accordionElements}
                                onChangeOpened={(indexes) => setAccSelected(indexes)}/>
                    <KSpace spaces={2}/>
                    {waiting ? <h2><KSpinner size={KSpinnerSize.lg}/></h2> : (
                        <KButton id={"payment-method-main"} text={buttonLabel} disabled={buttonDisabled}
                                 fill={true}
                                 onClick={async () => {
                                     setWaiting(true);
                                     try {
                                         try {
                                             switch (accSelected[0] + (hasSaved ? 0 : 1)) {
                                                 case 0:
                                                     setAllWaiting(true);
                                                     if (paymentInfo?.defaultPaymentMethodId !== selectedId && selectedId) {
                                                         await onSetDefaultPaymentMethod(selectedId);
                                                     }
                                                     break;
                                                 case 1:
                                                     if (!stripe || !elements) {
                                                         showAlert(I18n.t("payment.stripeError"));
                                                         return;
                                                     }
                                                     const payloadCard = await stripe.createPaymentMethod({
                                                         type: "card",
                                                         card: elements.getElement(CardElement)!
                                                     });
                                                     setAllWaiting(true);
                                                     if (!payloadCard || !payloadCard.paymentMethod?.id) {
                                                         showAlert(I18n.t("payment.cardError"));
                                                         return;
                                                     } else {
                                                         await onAddPaymentMethod(payloadCard.paymentMethod.id);
                                                         setAccSelected([0]);
                                                     }
                                                     break;
                                                 case 2:
                                                     if (!stripe || !elements) {
                                                         showAlert(I18n.t("payment.stripeError"));
                                                         return;
                                                     }
                                                     if (!sepaEmail || !sepaName) {
                                                         showAlert(I18n.t("payment.sepaMissingDataError"));
                                                         return;
                                                     }
                                                     const payloadIban = await stripe.createPaymentMethod({
                                                         type: "sepa_debit",
                                                         billing_details: {
                                                             name: sepaName,
                                                             email: sepaEmail,
                                                         },
                                                         sepa_debit: elements.getElement(IbanElement)!
                                                     });
                                                     setAllWaiting(true);
                                                     if (!payloadIban || !payloadIban.paymentMethod?.id) {
                                                         showAlert(I18n.t("payment.sepaError"));
                                                         return;
                                                     } else {
                                                         await onAddPaymentMethod(payloadIban.paymentMethod.id);
                                                         setAccSelected([1]);
                                                     }
                                                     break;
                                             }

                                             await onHandler();
                                         } catch (err) {
                                             showAlert(I18n.t("payment.setError"));
                                         }
                                     } finally {
                                         setWaiting(false);
                                         setAllWaiting(false);
                                     }
                                 }}
                        />
                    )}
                    <div className="powered">Powered by <i className="fal fab fa-stripe fa-2x"/></div>
                </>
            )}
        </div>
    );
}
