import React from "react";
import {KSpinner, KSpinnerSize} from "@kopjra/uikit";

export interface BigWaiterProps {
}

export const BigWaiter: React.FC<BigWaiterProps> = () => {
    return (
        <div style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0}}>
            <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <KSpinner size={KSpinnerSize.xxl}/>
            </div>
        </div>
    );
}