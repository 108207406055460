import {connect} from "react-redux";
import {DispatchProps, InnerProps, Payment, StateProps} from "../components/Payment";
import {RootState, ThunkDispatch} from "../types";
import {doGetBillingInfo, doResendEmail} from "../actions/thunks/customer";

function mapStateToProps({i18n, product, customer}: RootState): StateProps {
    return {
        locale: i18n.locale,
        cart: product.cart,
        promotion: product.promotion,
        oldCart: product.oldCart,
        customer: customer.customer,
        billingInfo: customer.billingInfo,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onResendEmail: () => dispatch(doResendEmail()),
        onGetBillingInfo: () => dispatch(doGetBillingInfo()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Payment);