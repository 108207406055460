import {BrowserRouter, Location, NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import React, {FC, useEffect, useState} from "react";
import {JustChildren} from "../types";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {KErrorPage} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";

export let navigate: NavigateFunction;
export let currentLocation: Location;

export interface ProtectedRouteProps {
    element: React.ComponentType;
    requiredClaims?: string[];
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ element, requiredClaims }) => {
    const {isAuthenticated, getIdTokenClaims} = useAuth0();
    const [claims, setClaims] = useState<string[] >([]);

    useEffect(() => {
        if (isAuthenticated && requiredClaims) {
            getIdTokenClaims().then((c) => {
                setClaims((c && c["assigned_roles"]) || []);
            });
        }
    }, [getIdTokenClaims, isAuthenticated, requiredClaims]);

    if (!isAuthenticated || !requiredClaims || requiredClaims.some((c) => claims.includes(c))) {
        const WithAuthentication = withAuthenticationRequired(element);
        return <WithAuthentication/>;
    } else {
        return <KErrorPage title={<Translate value="error.page"/>} code={"403"} message={<Translate value="error.forbidden"/>}/>;
    }
}

export const NavigationExtractor: FC<JustChildren> = ({ children }: JustChildren) => {
    navigate = useNavigate();
    currentLocation = useLocation();
    return <>{children}</>;
}

export const HookedBrowserRouter: FC<JustChildren> = ({ children }: JustChildren) => (
    <BrowserRouter>
        <NavigationExtractor>{children}</NavigationExtractor>
    </BrowserRouter>
);

/**
 * This hook can be used in any component inside the HookedBrowserRouter
 * to get the query parameters' Map
 */
export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export const Routes = {
    ALL: "*",
    HOME: "/",
    USER_ACCOUNT: "/user/account",
    CART: "/cart",
    PAYMENT: "/payment",
    USER_BILLING: "/billing",
    USER_PAYMENT: "/userpayment",
    USER_SUBSCRIPTIONS: "/usersubscriptions",
    EMAIL_VERIFIED: "/email_verified",
    USER_INVOICES: "/userinvoices",
    SUPER_USE: "/superuse",
    SUPER_ADMIN: "/superadmin",

    SOLUTION_DETAIL(solution?: string, id?: string): string {
        return solution ? `/${solution}${id ? `?cartId=${id}` : ""}` : "/:solution";
    },
}
