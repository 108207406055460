import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {
    KButton,
    KBUTTON_SIZE,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KCard,
    KContainer,
    KSpace,
    KStepProgress
} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {BigWaiter} from "./BigWaiter";
import {Translate} from "react-redux-i18n";
import {Routes} from "../utils/router";
import BillingInfoForm from "../containers/BillingInfoForm";
import {BillingInfo, Customer, hasBillingInfo} from "../types/customer";
import PaymentInfo from "../containers/PaymentInfo";
import OrderConfirmation from "../containers/OrderConfirmation";
import {CartType} from "../types/products";
import fight from "../utils/fight.gif";
import BEStripe from "stripe";
import {computeCartTotal} from "../utils/commons";
import {SecureRow} from "./SecureRow";

export interface StateProps {
    locale: string;
    cart: CartType,
    promotion?: BEStripe.PromotionCode;
    oldCart?: CartType,
    customer?: Customer,
    billingInfo?: BillingInfo;
}

export interface DispatchProps {
    onResendEmail: () => Promise<void>;
    onGetBillingInfo: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

enum PHASE {
    access = "access",
    billing = "billing",
    payment = "payment",
    outcome = "outcome",
}

function getPhase(isAuthenticated: boolean, hasBillingInfo: boolean, payed?: boolean, discountedTotal?: number, hasMetered: boolean = false): PHASE {
    if (!isAuthenticated) {
        return PHASE.access;
    } else if (!hasBillingInfo && !payed && (discountedTotal !== 0 || hasMetered)) {
        return PHASE.billing;
    } else if (!payed) {
        return PHASE.payment;
    } else {
        return PHASE.outcome;
    }
}

function getPhaseIndex(phase: PHASE): number {
    return Object.values(PHASE).indexOf(phase);
}

export const Payment: React.FC<Props> = ({promotion, onGetBillingInfo, locale, cart, billingInfo, customer, oldCart, onResendEmail}) => {
    const {isAuthenticated, loginWithRedirect, user} = useAuth0();
    const [phase, setPhase] = useState<PHASE | undefined>(undefined);
    const [payed, setPayed] = useState(false);
    const [easter, setEaster] = useState(false);
    const [discountedTotal, hasMetered] =  computeCartTotal(cart, promotion);

    useEffect(() => {
        if (customer && !billingInfo) {
            onGetBillingInfo().catch(console.log);
        }
    }, [customer, onGetBillingInfo, billingInfo]);
    useEffect(() => {
        const p = getPhase(!!(isAuthenticated && user?.email_verified), hasBillingInfo(billingInfo), payed, discountedTotal, hasMetered);
        setPhase(p);
    }, [isAuthenticated, payed, user, billingInfo, discountedTotal, hasMetered]);

    function getPhaseElement(phase: PHASE): JSX.Element | null {
        switch (phase) {
            case PHASE.access:
                return (
                    <Col lg={10} md={12} sm={12}>
                        {isAuthenticated ? (
                            <KCard header={<Translate value={`payment.${phase}`}/>}>
                                <h2 className="thinWeight text-start">
                                    <Translate value="payment.confirmEmail" email={user?.email} dangerousHTML={true}/>
                                </h2>
                                <KSpace spaces={2}/>
                                <KButton
                                    id={"user-verified-check"}
                                    text={<><i className="fal fa-angle-double-right" style={{fontSize: 24, position: "relative", top: 3}}/> <Translate value="payment.verified"/></>}
                                    size={KBUTTON_SIZE.nm}
                                    variant={KBUTTON_VARIANT.primary}
                                    type={KBUTTON_TYPE.button}
                                    onClick={() => window.location.reload() }
                                />
                                <KSpace spaces={2}/>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <h4><a className="link" onClick={() => onResendEmail()}><Translate value="payment.resendEmail"/></a></h4>
                            </KCard>
                        ) : (
                            <KCard header={<Translate value={`payment.${phase}`}/>}>
                                <h2 className="thinWeight"><Translate value="payment.registerLoginTitle"/></h2>
                                <KSpace/>
                                <KButton
                                    id={"register-login"}
                                    text={<><i className="fal fa-sign-in"/> <Translate value="payment.registerLoginButton"/></>}
                                    size={KBUTTON_SIZE.nm}
                                    variant={KBUTTON_VARIANT.primary}
                                    type={KBUTTON_TYPE.button}
                                    onClick={() => loginWithRedirect({
                                        appState: {
                                            returnTo: Routes.PAYMENT,
                                        },
                                        ui_locales: locale,
                                    })}
                                />
                            </KCard>
                        )}
                    </Col>
                );
            case PHASE.billing:
                return (
                    <Col lg={6} md={10} sm={12}>
                        <KCard header={<Translate value={`payment.${phase}`}/>}>
                            <BillingInfoForm confirmEmail={false}/>
                        </KCard>
                    </Col>
                );
            case PHASE.payment:
                return (
                    <Col lg={6} md={10} sm={12}>
                        <KCard header={<Translate value={`payment.${phase}`}/>}>
                            <PaymentInfo onPaymentFinished={() => setPayed(true)}/>
                        </KCard>
                    </Col>
                );
            case PHASE.outcome:
                return (
                    <OrderConfirmation/>
                );
        }
    }

    const onClickEaster = (e: React.MouseEvent<HTMLElement>) => {
        if (e.altKey && e.shiftKey) {
            setEaster(!easter);
        }
    };

    return (
        <KContainer marginTop={75} fluid={"md"}>
            {
                (Object.keys(cart).length > 0 || payed) ? (phase ? (
                    <>
                        {phase !== PHASE.outcome ? (
                            <>
                                <Row>
                                    <Col lg={6} md={8} sm={12}>
                                        <KStepProgress states={Object.values(PHASE).filter((value) => value !== PHASE.outcome).map((p) => (<Translate value={`payment.${p}`}/>))} activeIndex={getPhaseIndex(phase)}/>
                                    </Col>
                                </Row>
                                <KSpace spaces={3}/>
                            </>
                        ) : null}
                        <Row>
                            {getPhaseElement(phase)}
                        </Row>
                    </>
                ) : <BigWaiter/>) : (
                    <>
                        <KSpace spaces={3}/>
                        <h1>{!easter ? (
                            <i
                                onClick={onClickEaster}
                                className="fal fa-3x fa-person-dolly-empty"
                            />
                        ) : (
                            <img src={fight} alt={"Easter"} width={250} onClick={onClickEaster} style={{borderTop: "1px #000 solid", borderBottom: "1px #000 solid"}}/>
                        )}</h1>
                        <KSpace/>
                        <h3><Translate value="payment.emptyCart"/></h3>
                        <h4><Translate value="payment.gotoStore"/> <a href="/" className="link">store</a></h4>
                        <KSpace spaces={6}/>
                    </>
                )
            }
            <SecureRow/>
        </KContainer>
    );
}
