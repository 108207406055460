import React from "react";
import {Col, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import stripeImg from "../resources/imgs/stripe.svg";
import porfesrImg from "../resources/imgs/porfesr_new.png";

export interface Props { }

export const SecureRow: React.FC<Props> = () => {
    return (
        <Row>
            <Col md={6} className="secure text-center">
                <i className="fal fa-lock lock"/>
                <Translate value={"cart.secure"} className="text"/>
                <img className="pstripe" src={stripeImg} alt="Powered by stripe" width={100}/>
                <div className="cards">
                    <i className="fal fab fa-cc-visa"/>
                    <i className="fal fab fa-cc-mastercard"/>
                </div>
                <hr/>
                <Translate value={"cart.financed"} className="financedText"/>
                <img className="porfesr" src={porfesrImg} alt="Powered by stripe" width={600}/>
            </Col>
        </Row>
    );
};