import {createAction} from "typesafe-actions";
import {
    BillingInfo,
    Customer,
    CustomerConsumptionStats,
    Invoice,
    PaymentInfo,
    PlanSubscriptionWithTotal
} from "../types/customer";
import Stripe from "stripe";
import {Paginated} from "../utils/commons";

export const gotCustomer = createAction("CUSTOMER/got", (customer?: Customer) => customer)();
export const gotPromotionCode = createAction("CUSTOMER/got Promotion code", (promotionCode?: Stripe.PromotionCode) => promotionCode)();
export const canceledSubscription = createAction("CUSTOMER/canceled subscription", (subscription: Stripe.Subscription) => subscription)();
export const gotBillingInfo = createAction("CUSTOMER/got billing info", (billingInfo?: BillingInfo) => billingInfo)();
export const savedBillingInfo = createAction("CUSTOMER/saved_billing_info", (billingInfo?: BillingInfo) => billingInfo)();
export const gotPaymentInfo = createAction("CUSTOMER/got payment info", (paymentInfo?: PaymentInfo) => paymentInfo)();
export const gotInvoices = createAction("CUSTOMER/got_invoices", (invoices?: Paginated<Invoice>) => invoices)();
export const gotSubscriptions = createAction("CUSTOMER/got_subscriptions", (subscriptions: PlanSubscriptionWithTotal[], total: number) => ({subscriptions, total}))();
export const setSubscribing = createAction("CUSTOMER/set_subscribing", (subscribing: boolean) => subscribing)();
export const gotConsumptionStats = createAction("CUSTOMER/got_consumption_stats", (stats: CustomerConsumptionStats[], next?: string) => ({stats, next}))();