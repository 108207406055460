import {connect} from "react-redux";
import {DispatchProps, InnerProps, PaymentMethods, StateProps} from "../components/PaymentMethods";
import {RootState, ThunkDispatch} from "../types";
import {doAddPaymentMethod, doDeletePaymentMethod, doGetPaymentInfo, doSetDefaultPaymentMethod} from "../actions/thunks/customer";

function mapStateToProps({customer}: RootState): StateProps {
    return {
        customer: customer.customer,
        billingInfo: customer.billingInfo,
        paymentInfo: customer.paymentInfo,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onAddPaymentMethod: (pi) => dispatch(doAddPaymentMethod(pi)),
        onSetDefaultPaymentMethod: (pi) => dispatch(doSetDefaultPaymentMethod(pi)),
        onDeletePaymentMethod: (pi) => dispatch(doDeletePaymentMethod(pi)),
        onGetPaymentInfo: () => dispatch(doGetPaymentInfo()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(PaymentMethods);