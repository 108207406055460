import React, {useState} from "react";
import {CartType, ExclusivePrices, priceTotal, Product} from "../types/products";
import {KButton, KBUTTON_VARIANT, KSpace} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {PlanDetail} from "./PlanDetail";
import {createId, decomp} from "../utils/commons";
import {Alert, Col, Row} from "react-bootstrap";

export interface ProductDetailProps {
    product: Product;
    editId?: string;
    locale: string;
    cartPiece: CartType;
    totalProducts: number;
    exclusives?: ExclusivePrices;
    directSale: boolean;
    onChange: (cartPiece: CartType, removeId?: string) => void;
}

export const ProductDetail: React.FC<ProductDetailProps> = ({directSale, exclusives, totalProducts, product, onChange, cartPiece, editId}) => {
    const [selectedProduct, setSelectedProduct] = useState<boolean>(totalProducts === 1 || (!!editId && decomp(editId).sid === product.sid));

    const [generatedId] = useState(editId || createId(product));

    const exclusivePrice = exclusives && exclusives[product.id];

    function handleRemoval() {
        const tempCartPiece = {...cartPiece};
        delete tempCartPiece[generatedId];
        onChange(tempCartPiece, generatedId);
    }
    const multipleProductsOrEdit = totalProducts > 1 && !editId;
    return (
        <>
            <Row>
                <Col xl={multipleProductsOrEdit ? 1 : 12} lg={12} className="text-center">
                    {multipleProductsOrEdit ? (
                        <>
                            <img className="d-none d-xl-block" src={product.icon} height={70} alt={`${product.id} logo`} style={{marginLeft: -20}}/>
                            <div className="d-xl-none">
                                <img className="d-xl-none" src={product.icon} height={70} alt={`${product.id} logo`}/>
                                <KSpace/>
                            </div>
                        </>
                    ) : (
                        <>
                            <img src={product.icon} height={70} alt={`${product.id} logo`}/>
                            <KSpace/>
                        </>
                    )}
                </Col>
                <Col xl={multipleProductsOrEdit ? 3 : 12} lg={12} className="relative">
                    {multipleProductsOrEdit ? (
                        <>
                            <p className="card-vertical-center d-none d-xl-block">
                                <Translate className="card-product" value={`${product.id}.title`}/><br/>
                                <Translate value={`${product.id}.subtitle`}/>
                            </p>
                            <div className="d-xl-none">
                                <h5><Translate value={`${product.id}.title`}/></h5>
                                <h6><Translate value={`${product.id}.subtitle`}/></h6>
                            </div>
                        </>
                    ) : (
                        <>
                            <h5><Translate value={`${product.id}.title`}/></h5>
                            <h6><Translate value={`${product.id}.subtitle`}/></h6>
                        </>
                    )}
                </Col>
                <Col xl={multipleProductsOrEdit ? 5 : 12} lg={12} className="relative">
                    {multipleProductsOrEdit ? (
                        <>
                            <p className="card-description d-xl-none"><Translate value={`${product.id}.description`}/></p>
                            <p className="card-vertical-center d-none d-xl-block"><Translate value={`${product.id}.description`}/></p>
                        </>
                    ) : (
                        <p className="card-description"><Translate value={`${product.id}.description`}/></p>
                    )}
                </Col>
                {multipleProductsOrEdit ? (
                    <Col xl={multipleProductsOrEdit ? 3 : 12} lg={12} className="relative">
                        <div className="card-vertical-center">
                            {directSale ?
                                <h4><a className="link" href={`https://kopjra.pipedrive.com/scheduler/zXMbzdCQ/chiedi-informazioni-su-web-trust`} target="_blank"
                                   rel="noopener noreferrer"><Translate value="home.request"/></a></h4>
                                :
                                <KButton id={"product-delete"}
                            text={selectedProduct ? <><i className="fal fa-trash"/> <Translate value={"generic.remove"}/></> : <><i className="fal fa-angle-double-right"/> <Translate value={"generic.select"}/></>} fill={true} variant={selectedProduct ? KBUTTON_VARIANT["secondary"] : KBUTTON_VARIANT["primary"]} onClick={() => {
                                    if (selectedProduct) {
                                        handleRemoval();
                                    }
                                    setSelectedProduct(!selectedProduct);
                                }}/>
                            }
                        </div>
                    </Col>
                ) : null}
            </Row>
            {selectedProduct ? <>
                {multipleProductsOrEdit ? <div className="divider"/> : null}
                {exclusivePrice && priceTotal(exclusivePrice.price) > 0 ? (
                    <>
                        {multipleProductsOrEdit ? <KSpace/> : null}
                        <Row>
                            <Col xl={multipleProductsOrEdit ? 9 : 12} lg={12}>
                                <Alert variant={exclusivePrice.modifiable ? "warning" : "danger"}>
                                    <Translate value={exclusivePrice.modifiable ? `${product.id}.exclusiveWarn` as any : `${product.id}.exclusiveError` as any}/>
                                </Alert>
                            </Col>
                        </Row>
                    </>
                ) : null}
                <Row>
                    <Col xl={multipleProductsOrEdit ? 9 : 12} lg={12}>
                        {!directSale ?
                            <PlanDetail exclusivePrice={exclusivePrice?.price} readonly={!!exclusivePrice && !exclusivePrice.modifiable && priceTotal(exclusivePrice.price) > 0} product={product} id={generatedId} onChange={(cartPiece) => onChange(cartPiece)} cartPiece={cartPiece}/> : null
                        }
                    </Col>
                </Row>
            </> : null}
            {/*{(detail.plans.length > 1) ? (*/}
            {/*    <Row>*/}
            {/*        <Col lg={3} md={4}>*/}
            {/*            <KLabel text={<Translate value="productDetail.selectPlan"/>}/>*/}
            {/*        </Col>*/}
            {/*        <Col lg={9} md={8}>*/}
            {/*            <KSelect<Plan>*/}
            {/*                id={`${pid}-plan-select`}*/}
            {/*                name={`${pid}-plan-select`}*/}
            {/*                options={detail.plans.map((p) => ({*/}
            {/*                    value: p,*/}
            {/*                    label: `${I18n.t(`${pid}.plan.${p.name}.title`)} - ${I18n.t(`generic.duration.${p.duration}`)}`,*/}
            {/*                }))}*/}
            {/*                value={selectedPlan}*/}
            {/*                placeholder={<Translate value="generic.select"/>}*/}
            {/*                clearable={true}*/}
            {/*                onChange={(value) => {*/}
            {/*                    let mandatoryStatus = createMandatoryAddonsStatus(pid, value as Plan, selectedPlan);*/}
            {/*                    if (mandatoryStatus) {*/}
            {/*                        onChange(mandatoryStatus);*/}
            {/*                    }*/}
            {/*                    onSelectPlan(value as Plan | undefined);*/}
            {/*                    setSelectedPlan(value as Plan);*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*) : null}*/}
            {/*{selectedPlan ? <PlanDetail pid={pid} product={detail} onChange={(cartPiece) => onChange(cartPiece)} priceStatus={priceStatus}/> : null}*/}
        </>
    );
}
