import React from "react";
import {KCard, KContainer} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import BillingInfoForm from "../containers/BillingInfoForm";
import {showAlert} from "../utils/commons";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const UserBillingInfo: React.FC<Props> = () => {
    return (
        <KContainer>
            <Row>
                <Col lg={6} md={10} sm={12}>
                    <KCard header={<Translate value={`user.billing`}/>}>
                        <BillingInfoForm buttonText={<Translate value="user.save"/>} onSubmitted={() => {
                            showAlert(`${I18n.t("user.billingSaved")} + ${Math.random()}`, "success");
                        }} confirmEmail={false}/>
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    );
}