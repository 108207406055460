import {createAction} from "typesafe-actions";
import {CartType, ExclusivePrices, Product, SubscriptionError} from "../types/products";
import {SolutionKey} from "../types/solutions";
import Stripe from "stripe";

export const gotProducts = createAction("PRODUCTS/got", (products: Product[], taxRate: Stripe.TaxRate) => ({products, taxRate}))();
export const selectSolution = createAction("PRODUCTS/select_solution", (solutionKey: SolutionKey) => solutionKey)();
export const addToCart = createAction("PRODUCTS/add_to_cart", (cartPiece: CartType) => cartPiece)();
export const removeFromCart = createAction("PRODUCTS/remove_from_cart", (id: string | string[]) => id)();
export const emptyCart = createAction("PRODUCTS/empty_cart")();
export const setOldCart = createAction("PRODUCTS/set_old_cart", (cart: CartType) => cart)();
export const initSubscriptionErrors = createAction("PRODUCTS/reset_subscription_errors")();
export const addSubscriptionError = createAction("PRODUCTS/add_subscription_error", (pid: string, error: SubscriptionError) => ({pid, error}))();
export const resetOldCart = createAction("PRODUCTS/reset_old_cart")();
export const setCartLoading = createAction("PRODUCTS/set_cart_loading", (loading: boolean) => loading)();
export const cartError = createAction("PRODUCTS/cart_error")();
export const setExclusivePrices = createAction("PRODUCTS/set_exclusive_prices", (exclusivePrices?: ExclusivePrices) => exclusivePrices)();