import React from "react";
import {KCard, KContainer, KUserProfile} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {Col, Row} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {UserProfileData} from "../types";

export interface StateProps {
}

export interface DispatchProps {
    onUpdateUserProfile: (up: UserProfileData) => Promise<void>;
    onResendEmail: () => Promise<void>;
    onResetPassword: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const UserProfile: React.FC<Props> = ({onResendEmail, onResetPassword, onUpdateUserProfile}: Props) => {
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();

    return isAuthenticated ? (
        <KContainer>
            <Row>
                <Col md={6}>
                    <KCard header={<Translate value="user.profile"/>}>
                        <KUserProfile user={{
                            email: user?.email || "",
                            email_verified: !!user?.email_verified,
                            sub: user?.sub || "",
                            name: user?.name,
                            picture: user?.picture || ""
                        }} onResetPassword={onResetPassword} onResendEmail={onResendEmail}
                                      onUpdateUserProfile={onUpdateUserProfile} getToken={getAccessTokenSilently}/>
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    ) : null;
}
